import React, { useState } from 'react';
import Vector from '../images/Group.png'
import Logo_black from '../images/logo.png'
import White_Vector from '../images/alloy-white-logo.png'
import Login_key from '../images/login_key.png'
import Login_mail from '../images/login_mail.png'
import { Routes, Route, Link, NavLink } from "react-router-dom";
import Button from 'react-bootstrap/Button';
import Arrow from "../images/Vector9.png";
import Google_logo from "../images/Google__G__Logo 1.png";
import Modal from 'react-bootstrap/Modal';
const Header = () => {
    const [active, setactive] = useState(true)
    const [logregcon, setlogregcon] = useState(false)
    const [show, setShow] = useState(false);
    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);
    return (
        <>
        
            <header id="header" className={active === true ? "header" : "header active"}  >
                <div className="container-fluid lprp">
                <Modal show={show} onHide={handleClose}>
                    <Modal.Body>
                        <div className="login_register_modal">
                            <div className='modal_logo_sec'>
                                <img className='img-fluid' src={Logo_black} alt="Logo" />
                            </div>
                            <div className='modal_login_converter py-4'>
                                <div className='converter_main'>
                                    <div className={logregcon==false?"absolute_sight":"absolute_sight-1"}>{logregcon==false?"Log in":"Register"}</div>
                                    <div onClick={()=>{setlogregcon(false)}} className='Login_sight'>Log in</div>
                                    <div onClick={()=>{setlogregcon(true)}} className='register_sight'>Register</div>
                                </div>
                            </div>
                            {logregcon==false?
                            <div>
                                <div className='modal_login_input'>
                                    <div className='email_login d-flex flex-column'>
                                        <label className='m-0'>E-Mail Address</label>
                                        <div className='img-mail'>
                                            <input type="email" placeholder='Enter email address'/>
                                            <img className='img-fluid' src={Login_mail} alt="" />
                                        </div>
                                    </div>
                                    <div className='password_login d-flex flex-column mt-2'>
                                        <label className='m-0'>Password</label>
                                        <div className='img-key'>
                                            <input type="password" placeholder='Enter Password'/>
                                            <img className='img-fluid' src={Login_key} alt="" />
                                        </div>
                                    </div>
                                </div>
                                <div className='forget_password'>
                                    <p className='m-0'>Forgot Password</p>
                                </div>
                                <div className='w-100'>
                                    <div className='d-flex align-items-center justify-content-center price-btn m-0 p-0'>
                                            <button className='w-100 mb-3'>Login<img className='btn-animation-arrow' src={Arrow} alt="" /><img className='btn-animation-arrow-2' src={Arrow} alt="" /></button>
                                        </div>
                                        <div className="Login_with_google m-0 p-0">
                                            <button><img src={Google_logo} alt="" />SIGN IN WITH GOOGLE</button>
                                        </div>
                                </div>
                            </div>
                            :
                                <div>
                                <div className='modal_login_input'>
                                    <div className='email_login d-flex flex-column mt-2'>
                                        <label className='m-0'>Name</label>
                                        <div className='img-mail'>
                                            <input type="email" placeholder='Enter Name'/>
                                            <img className='img-fluid' src={Login_mail} alt="" />
                                        </div>
                                    </div>
                                    <div className='email_login d-flex flex-column mt-2'>
                                        <label className='m-0'>E-Mail Address</label>
                                        <div className='img-mail'>
                                            <input type="email" placeholder='Enter email address'/>
                                            <img className='img-fluid' src={Login_mail} alt="" />
                                        </div>
                                    </div>
                                    <div className='password_login d-flex flex-column mt-2'>
                                        <label className='m-0'>Password</label>
                                        <div className='img-key'>
                                            <input type="password" placeholder='Enter Password'/>
                                            <img className='img-fluid' src={Login_key} alt="" />
                                        </div>
                                    </div>
                                    <div className='password_login d-flex flex-column mt-2'>
                                        <label className='m-0'>Confirm Password</label>
                                        <div className='img-key'>
                                            <input type="password" placeholder='Enter Password'/>
                                            <img className='img-fluid' src={Login_key} alt="" />
                                        </div>
                                    </div>
                                </div>
                                <div className='w-100 mt-3'>
                                    <div className='d-flex align-items-center justify-content-center price-btn m-0 p-0'>
                                            <button className='w-100 mb-3'>Register<img className='btn-animation-arrow' src={Arrow} alt="" /><img className='btn-animation-arrow-2' src={Arrow} alt="" /></button>
                                        </div>
                                        <div className="Login_with_google m-0 p-0">
                                            <button><img src={Google_logo} alt="" />SIGN UP WITH GOOGLE</button>
                                        </div>
                                </div>
                            </div>
                            }
                        </div>
                    </Modal.Body>
                </Modal>
                    <div className="row">
                        <div className="col-md-6">
                            <div className='header_details' id='menu'>
                                <ul>
                                    <Link to="/"><img style={{ height: '46px' }} src={Vector} alt="Logo" /></Link>
                                    <li><NavLink to="/about-us" className='nav-links-bar'>ABOUT</NavLink> </li>
                                    <li><NavLink to="/blog" className='nav-links-bar'>NEWS &nbsp; & &nbsp;RESOURCES</NavLink></li>
                                    <li><NavLink to="/contact-us" className='nav-links-bar'>CONTACT</NavLink></li>
                                </ul>
                            </div>
                        </div>
                        <div className="overlay_menu">
                            <ul>
                                <img style={{ height: '46px' }} src={White_Vector} alt="Logo" />
                                <li><Link to='/about-us'>ABOUT</Link> </li>
                                <li><Link to='/blog'>NEWS & RESOURCES</Link> </li>
                                <li><Link to='/contact-us'>CONTACT</Link></li>
                                <div className='side_bar_top_bottom'>
                                    {/* <button className="overlay_btn">Login</button> */}
                                    <Link to="/createProfile" className='overlay_btn'>Login</Link>
                                    <Link to="/dashboard" className="overlay_btn2">Download on the App store</Link>
                                </div>
                            </ul>
                        </div>
                        <div className='responsive-slider'>
                            <button className="burger" style={{ cursor: "pointer" }} onClick={() => setactive(!active)}>

                            </button>
                        </div>
                        <div className="col-md-6">
                            <div className="header_btn">
                                <button onClick={handleShow} className='header_button'>Login</button>
                                <Link to="/dashboard" className="header_button">Download on the App store</Link>

                            </div>
                        </div>
                    </div>
                </div>
            </header>
        </>
    )
}
export default Header;