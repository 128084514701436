import React from 'react'
import message from '../images/account_sub/Message.png'
import Header from './Header';
import Footer from './Footer'
import Arrow from "../images/Vector9.png"
import Group_115 from '../images/Group 115.png'
import user from '../images/User_duotone_line.png'
import vector from '../images/Vector 9 (2).png'
import message_input from '../images/Message_duotone_line.png'
import TextField from '@mui/material/TextField';
import { Select } from 'antd';
import Autocomplete from '@mui/material/Autocomplete';
// const options = ['I have a question', "I\'d like to request a demo ", "I\'d like to report a problem ", "I\'d like to discuss a partnering "];
const Contact = () => {
    const [value, setValue] = React.useState('');
    const [inputValue, setInputValue] = React.useState('');
    const { Option, OptGroup } = Select;
    const handleChange = (value) => {
        console.log(`selected ${value}`);
    };
    return (

        <>
            <Header />
            <div className="container-fluid" style={{ position: 'relative' }}>
                <div className="form_bg"></div>
                <div className="row">
                    <div className="col-md-5">
                        <div className="contact_left-side">
                            <div className="contact_heading">
                                <h1>Get in touch</h1>
                                <p>Got any queries? We are here to help. Send us a message right now.</p>
                                <h4>Or you can also email us AT</h4>
                                <h5> <img src={message} alt="" style={{ height: '24px', width: '24px' }} /> <span>help@website.com </span> </h5>
                                <img style={{ paddingTop: '76px' }} src={Group_115} alt="" />
                            </div>
                        </div>
                    </div>
                    <div className="col-md-1"></div>
                    <div className="col-md-6">
                        <div className="form_box">
                            <h3 >Send a <span style={{ color: '#8D0AE2' }}>Message</span></h3>
                            <label htmlFor="name"><b> Name</b></label>
                            <div className="cnt-img-hold" style={{ position: 'relative' }}>
                                <img src={user} alt="" style={{ position: 'absolute', right: '11px', top: '7px' }} />
                                <input placeholder='John Doe' type="text" name='name' className="form-control" />
                            </div>
                            <br />
                            <label htmlFor="Email"><b> Email Address</b></label>
                            <div className="cnt-img-hold" style={{ position: 'relative' }}>
                                <input placeholder='John Doe' type="email" name='email' className="form-control" />
                                <img src={message_input} alt="" style={{ position: 'absolute', right: '11px', top: '7px' }} />
                            </div>
                            <br />
                            <label htmlFor="subject"><b>Select a Subject</b></label>
                            <br />
                            <Select
                                defaultValue="Select"
                                style={{
                                    width: '100%'
                                }}
                                onChange={handleChange}
                            >
                                <Option value="jack">I have a question</Option>
                                <Option value="lucy">I'd like to request a demo</Option>
                                <Option value="Yiminghe">I'd like to report a problem</Option>
                                <Option value="Yiminghe">I'd like to discuss a partnering</Option>
                            </Select>
                            <br />
                            <br />
                            {/* <img src={vector} alt="" style={{ position: 'absolute', right: '5.5rem', top: '22rem' }} /> */}
                            <label htmlFor="message"><b> Enter  Message</b></label>
                            <textarea placeholder='How can we help?' name="message" className='form-control' id="" cols="30" rows="10"></textarea>
                            <div className='d-flex align-items-center justify-content-center price-btn margin ' style={{ paddingTop: '23px', marginLeft: '-204px' }}>
                                <button style={{ width: '100%', textTransform: 'uppercase' }}>Send <img className='btn-animation-arrow' src={Arrow} alt="" /><img className='btn-animation-arrow-2' src={Arrow} alt="" /></button>
                            </div>
                        </div>
                    </div>

                    <div className="col-md-1"></div>
                </div>
            </div>
            <Footer />
        </>
    )
}

export default Contact;