import React from 'react';
import hero_img from '../images/phone.png';
import play from '../images/Polygon 1.png';
import Header from './Header';
import client_1 from '../images/download 1 (1).png';
import client_2 from '../images/download 1.png';
import client_3 from '../images/download 1 (2).png';
import client_4 from '../images/Mask group.png';
import app_img from '../images/Group1.png';
import Footer from './Footer';
import Dropslide from './dropslide';
import ClientCard from './clientcard';
import BGDArk from '../images/Hero-BG.png'
import MobileBanner from '../images/Group14.png'
import Slidering from "./slider"
import Questions from './question';
import Arrow from "../images/Vector9.png"
const Home = () => {
    return (
        <>
            <Header />
            <section id="hero">
                <div className="container-fluid">
                    <div className="row">
                        <div className="col-lg-6 col-md-12 col-sm-12">
                            <div className="hero-side_details">
                                <h1>Empowering You to Be in Command</h1>
                                <p>Take your betting into your own hands with our world-class, easy to use statistical platform.</p>
                                <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit.</p>
                                <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nunc vulputate libero et velit interdum, ac aliquet odio mattis.</p>
                                <div className="header-btns-two d-flex">
                                    <button className='try-allow-btn'>Try alloy for free</button>
                                    <div className='d-flex align-items-center justify-content-center price-btn m-0 p-0'>
                                        <button>Build you own strategy <img className='btn-animation-arrow' src={Arrow} alt="" /><img className='btn-animation-arrow-2' src={Arrow} alt="" /></button>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-6 col-md-12 col-sm-12">
                            <div className="hero_img">
                                <img src={hero_img} alt="" />
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <section className='pb-5'>
                <Slidering />
            </section>
            <section id="pricing" className="pt-5">
                <div className="pricing_bg"></div>
                <Dropslide />
            </section>
            <div className='desk-slider'>
                <img className='herobg-left' src={BGDArk} alt="" />
                <section id='alloy_about'>
                    <div className="container">
                        <div className="row">
                            <div className="col-md-6">
                                <div className="alloy_content">
                                    <h1>What is <span style={{ color: '#8D0AE2' }}>Alloy</span>?</h1>
                                    <p style={{ paddingBottom: '22px' }}>A sports betting research company designed for the fan. We value transparency and user control over everything. Easily build and test your own personalized sports betting strategies in three simple steps.</p>
                                    <div className="d-flex">
                                        <div className='d-flex align-items-center justify-content-center price-btn'>
                                            <button>Get started <img className='btn-animation-arrow' src={Arrow} alt="" /><img className='btn-animation-arrow-2' src={Arrow} alt="" /></button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="col-md-6" style={{ position: 'relative' }}>
                                    <div className="video">
                                        <div className="play_btn" style={{ position: 'absolute', top: '50%', left: '50%' }}>
                                            <img src={play} alt="" />
                                        </div>
                                    </div>

                            </div>
                        </div>
                    </div>
                </section>
            </div>
            <div className='responsive-slider '>
                <div className="alloy_bg"></div>
                <section id='alloy_about'>
                    <div className="container">
                        <div className="row">
                            <div className="col-md-6" style={{ position: 'relative' }}>
                                <div className="video">
                                    <div className="play_btn" style={{ position: 'absolute', top: '50%', left: '50%' }}>
                                        <img src={play} alt="" />
                                    </div>
                                </div>
                            </div>
                            <div className="col-md-6 mt-5">
                                <div className="alloy_content">
                                    <h1>What is <span style={{ color: '#8D0AE2' }}>Alloy</span>?</h1>
                                    <p style={{ paddingBottom: '22px' }}>A sports betting research company designed for the fan. We value transparency and user control over everything. Easily build and test your own personalized sports betting strategies in three simple steps.</p>
                                    <div className="d-flex">
                                        <div className='d-flex align-items-center justify-content-center price-btn'>
                                            <button>Get started <img className='btn-animation-arrow' src={Arrow} alt="" /><img className='btn-animation-arrow-2' src={Arrow} alt="" /></button>
                                        </div>
                                    </div>
                                </div>
                            </div>

                        </div>
                    </div>
                </section>
            </div>
            <section id="client">
                <div className="container-fluid">
                    <div className="client_heading" style={{ textAlign: 'center', color: ' #25408F', fontWeight: 600, paddingBottom: '35px' }}>
                        <h1>See Who's Talking About Alloy</h1>
                    </div>
                    <div className="clients_company">
                        <div className="container">
                            <div className="row">
                                <div className="col-md-4"><div className="box"><img src={client_2} alt="" /></div></div>
                                <div className="col-md-4"><div className="box"><img src={client_1} alt="" /></div></div>
                                <div className="col-md-4"><div className="box"><img src={client_3} alt="" /></div></div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <div className='crc'>
                <ClientCard />
            </div>
            <div className='crc pb-0'>
                <div className='contanier'>
                    <Questions />
                </div>
            </div>
            <div className='desk-slider'>
                <div className='container-fluid'>
                    <div className='container'>
                        <div className="col-md-12 py-5">
                            <div className="client_img-21">
                                <img width="100%" src={client_4} alt="" />
                                <div className='banner-img-left-box'>
                                    <h1> <span style={{ color: '#1EC130', fontWeight: 600 }}>Download</span>  the <br />  App Now</h1>
                                    <img className='paly-store-2' src={app_img} alt="" />

                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className='responsive-slider'>
                <div className='container-fluid'>
                    <div className="col-md-12">
                        <div>
                            <div className="client_img" style={{ textAlign: 'center', position: 'relative', paddingBottom: '10em' }}>
                                <img src={MobileBanner} alt="" />
                                <h1> <span style={{ color: '#1EC130', fontWeight: 600 }}>Download</span>  the <br />  App Now</h1>
                                <img className='paly-store' src={app_img} alt="" />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <Footer />
        </>
    )
}

export default Home;