import React from 'react';
import "../App.css";
import Crown from "../images/icon.png"
import PicturePic from "../images/profile.png"
import Quotes from "../images/qoute.png"
import Arrow from "../images/Vector-purple.png"
import star_1 from "../images/Star 1.svg"
import star_2 from "../images/Star 2.svg"

const ClientCard = () => {
    return (
        <>
            <section id='clientreview'>
                <div className='container-fluid'>
                    <div className='row'>
                        <div className='col-lg-4 col-md-6 col-sm-12'>
                            <div className='main-client-card-start mt-3'>
                                <div className='client-top-image'>
                                    <div className='pl-4 profile-pic-client'>
                                        <img className='profile-crown' src={Crown} alt="" />
                                        <img className='mt-2 crown-profile' src={PicturePic} alt="" />
                                    </div>
                                    <div className='d-flex flex-column px-4 py-2 client-card-review-name'>
                                        <div className='w-100'>
                                            <div className='d-flex align-items-center justify-content-between'>
                                                <h5>Jarrett Adams</h5>
                                                <div className='d-flex align-items-center rating'>
                                                    {/* <i class="fa fa-star-o" aria-hidden="true"></i> */}
                                                    <img src={star_1} alt="" />
                                                    <img src={star_1} alt="" />
                                                    <img src={star_1} alt="" />
                                                    <img src={star_1} alt="" />
                                                    <img src={star_2} alt="" />
                                                </div>
                                            </div>
                                            <h6 className='mt-2'>Sports Betting Nation on Clubhouse</h6>
                                        </div>
                                        <div className='d-flex mt-1 comment-of-client'>
                                            <i><h6>This is the first system that lets you go back and compare your current Wagers to similer wagers in the past i'd use it for research before i throw any big money down</h6></i>
                                        </div>
                                    </div>

                                    <div className='d-flex align-items-center justify-content-end pr-5 qoute-margin'>
                                        <img src={Quotes} alt="" />
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className='col-lg-4 col-md-6 col-sm-12'>
                            <div className='main-client-card-start mt-3'>
                                <div className='client-top-image'>
                                    <div className='pl-4 profile-pic-client'>
                                        <img className='profile-crown' src={Crown} alt="" />
                                        <img className='mt-2 crown-profile' src={PicturePic} alt="" />
                                    </div>
                                    <div className='d-flex flex-column px-4 py-2 client-card-review-name'>
                                        <div className='w-100'>
                                            <div className='d-flex align-items-center justify-content-between'>
                                                <h5>Jarrett Adams</h5>
                                                <div className='d-flex align-items-center rating'>
                                                    <img src={star_1} alt="" />
                                                    <img src={star_1} alt="" />
                                                    <img src={star_1} alt="" />
                                                    <img src={star_1} alt="" />
                                                    <img src={star_2} alt="" />
                                                </div>
                                            </div>
                                            <h6 className='mt-2'>Sports Betting Nation on Clubhouse</h6>
                                        </div>
                                        <div className='d-flex mt-1 comment-of-client'>
                                            <i><h6>This is the first system that lets you go back and compare your current Wagers to similer wagers in the past i'd use it for research before i throw any big money down</h6></i>
                                        </div>
                                    </div>

                                    <div className='d-flex align-items-center justify-content-end pr-5 qoute-margin'>
                                        <img src={Quotes} alt="" />
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className='col-lg-4 col-md-6 col-sm-12'>
                            <div className='main-client-card-start mt-3'>
                                <div className='client-top-image'>
                                    <div className='pl-4 profile-pic-client'>
                                        <img className='profile-crown' src={Crown} alt="" />
                                        <img className='mt-2 crown-profile' src={PicturePic} alt="" />
                                    </div>
                                    <div className='d-flex flex-column px-4 py-2 client-card-review-name'>
                                        <div className='w-100'>
                                            <div className='d-flex align-items-center justify-content-between'>
                                                <h5>Jarrett Adams</h5>
                                                <div className='d-flex align-items-center rating'>
                                                    <img src={star_1} alt="" />
                                                    <img src={star_1} alt="" />
                                                    <img src={star_1} alt="" />
                                                    <img src={star_1} alt="" />
                                                    <img src={star_2} alt="" />
                                                </div>
                                            </div>
                                            <h6 className='mt-2'>Sports Betting Nation on Clubhouse</h6>
                                        </div>
                                        <div className='d-flex mt-1 comment-of-client'>
                                            <i><h6>This is the first system that lets you go back and compare your current Wagers to similer wagers in the past i'd use it for research before i throw any big money down</h6></i>
                                        </div>
                                    </div>

                                    <div className='d-flex align-items-center justify-content-end pr-5 qoute-margin'>
                                        <img src={Quotes} alt="" />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className='d-flex align-items-center justify-content-center mt-4 price-btn-outinle mt-5 pt-5'>
                        <button>SIGN UP FREE TO GET YOURSELF IN TOP FAN LIST<img className='btn-animation-arrow' src={Arrow} alt="" /><img className='btn-animation-arrow-2' src={Arrow} alt="" /></button>
                    </div>
                </div>
            </section>
        </>
    )
};

export default ClientCard;