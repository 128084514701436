import React from 'react';
import "./App.css";
import 'antd/dist/antd.css';
import {
  BrowserRouter,
  Routes,
  Route,
} from "react-router-dom";
import Home from './component/Home';
import AboutUs from "./component/About";
import CreateProfile from "./component/AccountPro";
import Subscribe from "./component/AccountSub";
import ContactUs from "./component/Contact";
import Blog from "./component/bolg";
import BlogDetail from "./component/bolgdetail";
import Dashbaord from "./component/dashbard/dashboard";



const App = () => {
  return (
    <BrowserRouter>
    <Routes>
      <Route>
          <Route path="/" element={<Home />} />
          <Route path="/about-us" element={<AboutUs />}/>
          <Route path="/contact-us" element={<ContactUs />} />
          <Route path="/blog" element={<Blog />} />
          <Route path="/blogdetail" element={<BlogDetail />} />
          <Route path="/Subscribe" element={<Subscribe />} />
          <Route path="/createProfile" element={<CreateProfile />} />
          <Route path="/dashboard" element={<Dashbaord />} />
          
      </Route>
    </Routes>
  </BrowserRouter>
  );
};

export default App;