import React from 'react'
import face from '../images/social/facebook.png'
import insta from '../images/social/instagram.png'
import twitter from '../images/social/twitter.png'
import link from '../images/social/linkdin.png'
import disc from '../images/social/discord.png'
import twitch from '../images/social/twitch.png'
import footer_end from '../images/Rectangle 7.png'
import message from '../images/Message_duotone_line.png'
import { Link } from 'react-router-dom'
const Footer = () => {
    return (
        <><footer id="footer">
            <div className="container-fluid pt-5">
                <div className="row">
                    <div className="col-lg-3 col-md-6 col-sm-12">
                        <div className="quicklink">
                            <ul>
                                <h2>Quick Links</h2>
                                <li><Link to='/about-us'>About</Link> </li>
                                <li><Link to='/'>Platform</Link> </li>
                                <li><Link to='/blog'>Resource & Article</Link></li>
                                <li><Link to='/contact-us'>Contact</Link></li>
                            </ul>
                        </div>
                    </div>
                    <div className="col-lg-2 col-md-6 col-sm-12">
                        <div className="follow">
                            <ul>
                                <h2>Follow Us</h2>
                                <li> <img style={{ paddingRight: '17px' }} src={face} alt="" />Facebook</li>
                                <li> <img style={{ paddingRight: '18px' }} src={insta} alt="" />Instagram</li>
                                <li> <img style={{ paddingRight: '19px' }} src={disc} alt="" />Discord</li>
                            </ul>
                        </div>
                    </div>
                    <div className="col-lg-3 col-md-6 col-sm-12 footer-third-section">
                        <div className="extra">
                            <ul className='p-0'>
                                <li> <img style={{ paddingRight: '20px' }} src={link} alt="" />LinkedIn</li>
                                <li> <img style={{ paddingRight: '16px' }} src={twitter} alt="" />Twitter</li>
                                <li> <img style={{ paddingRight: '18px' }} src={twitch} alt="" />Twitch</li>
                            </ul>
                        </div>
                    </div>
                    <div className="col-lg-4 col-md-6 col-sm-12 footer-forth-section">
                        <div className="email w-100">
                            <h2>Newsletter</h2>
                            <h6 style={{ fontWeight: "600" }} className='py-2'>Email</h6>
                            <div className='w-100' style={{ position: "relative" }}>
                                <input style={{ position: 'relative' }} type="text" name='email' placeholder='Enter email address' />
                                <img style={{ position: 'absolute', right: '1rem', top: '10px' }} src={message} alt="" />
                            </div>
                            <br />
                            <button>Subscribe</button>
                        </div>
                    </div>
                </div>
            </div>
            <div className="footer_content">
                <div className='container'>
                    <p>Alloy Sports and the information provided are for entertainment purposes only. Alloy Sports is not a sports betting operator. This service is only intended for adult users. If you or someone you know has a gambling problem, you can call 1-800 GAMBLER for help.</p>
                </div>
            </div>
            <div className="footer_end">
                <div className="container-fluid">
                    <div className="row">
                        <div className="col-md-12">
                            <div className="footer_end_content pt-3">
                                <div className='row'>
                                    <div className='col-md-6 d-flex text-center'>
                                        <p style={{ fontWeight: "" }}>© ALLOY SPORTS</p>
                                        <p style={{ fontWeight: "600" }}>Terms & Conditions</p>
                                        <p style={{ fontWeight: "600" }}>Privacy Policy</p>
                                    </div>
                                    <div className='col-md-6 text-center'>
                                        <div className="right"><p>Powered By: <img src={footer_end} alt="" /></p> </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </footer>

        </>
    )
}

export default Footer