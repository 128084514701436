import React from 'react'
import Footer from './Footer'
import Header from './Header'
import play from '../images/Polygon 1.png';
import group_157 from '../images/Group 157.png'
import box_1 from '../images/Critical_duotone.png'
import box_2 from '../images/build.png'
import box_3 from '../images/Setting_vert.png'
import box_4 from '../images/bet-2.png'
import client_4 from '../images/Mask group.png';
import app_img from '../images/Group1.png';
import MobileBanner from '../images/Group14.png'
const About = () => {
    return (
        <>
            <Header />
            <section id="about_upper">
                <div className="about_bg-upper"></div>
                <div className="container-fluid">
                    <div className="row">
                        <div className="col-md-10">
                            <div className="about_upper-content">
                                <h1>Built By Fans for Fans</h1>
                                <h6>We are a group of passionate, data-driven sports fans who are tired of sportsbooks having the upper hand.</h6>
                                <h6>Every week we were placing our bets, looking at what the “experts” were saying, and it seemed like every week we were losing money to the books. There was no transparency. We had no control. We were tired of it. So we started Alloy to put the bettor first. To put fans in command.</h6>
                            </div>
                        </div>
                    </div>
                    <div className="row py-5">
                        <div className="col-md-5 d-flex align-items-center justify-content-center">
                            <div className="about_mid-left lpo m-0">
                                <h1>How to use <span style={{ color: '#8D0AE2' }}>Alloy</span>?</h1>
                                <h4>Using Alloy is easy</h4>
                                <p style={{ fontSize: '20px'}}>  You can watch the demo video of a user walking through the platform, and successfully creating their first strategy!</p>
                            </div>
                        </div>
                        <div className="col-md-7">
                            <div className="about_mid-right pr-4">
                                <div className="video width">
                                    <div className="play_btn" style={{ position: 'absolute', top: '50%', left: '50%' }}>
                                        <img src={play} alt="" />
                                    </div>
                                </div>
                            </div>

                        </div>
                    </div>
                </div>
                <div className="alloy_info">
                    <div className="container-fluid">
                        <div className="row">
                            <div className="col-md-6 d-flex align-items-center ">
                                <img className='img-fluid' src={group_157} alt="" />
                            </div>
                            <div className="col-md-6">
                                <div className="alloy_info-heading">
                                    <h1>What is <span style={{ color: '#8D0AE2' }}>Alloy</span>?</h1>
                                    <p>Alloy is a sports betting research company that values transparency and user control over everything. Our platform lets you build and test your own custom sports betting strategies in three simple steps. Combine different stats you think lead to winning to create your metric, filter out games that don't fit your criteria, and find profitable upcoming games to bet on that fit your strategy.
                                    </p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <section id="boxes">
                <div className="container-fluid">
                    <div className="row">
                        <div className="col-md-12">
                            <h6 style={{ color: "#25408F", fontWeight: 600, fontSize: '24px', textAlign: 'center', paddingTop: '116px' }}>Alloy is the only place where You:</h6>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-lg-3 col-md-6 col-sm-12">
                            <div className="about_box">
                                <img src={box_1} alt="" />
                                <p><b>YOU</b> decide what leads to winning.</p>
                            </div>
                        </div>
                        <div className="col-lg-3 col-md-6 col-sm-12">
                            <div className="about_box">
                                <img src={box_2} alt="" />
                                <p><b>YOU</b> choose the stats for your winning formula.</p>
                            </div>
                        </div>
                        <div className="col-lg-3 col-md-6 col-sm-12">
                            <div className="about_box">
                                <img src={box_3} alt="" />
                                <p><b>YOU</b>  filter down to the games you want to see.</p>
                            </div>
                        </div>
                        <div className="col-lg-3 col-md-6 col-sm-12">
                            <div className="about_box">
                                <img src={box_4} alt="" />
                                <p><b>YOU</b> can bet on the games that fit your strategy.</p>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <p style={{ fontWeight: 400, fontSize: '20px', textAlign: 'center', paddingTop: '29px' }}>No more trusting expert picks. No more relying on betting shows on TV. No more gut <br /> feelings without data to back it up. <br />
                <span style={{ color: '#000000', fontWeight: 700 }}>You are in command of your sports betting.</span></p>
            <section id="alloy_back">
                <h2>Who is behind  <span style={{ color: '#8D0AE2' }}>Alloy</span>?</h2>
                <h6>Alloy was built by a group of entrepreneurs based in Baltimore, MD and <br /> Washington, D.C.</h6>
                <h3>Want to learn more?</h3>
                <h4>Watch this video:<span style={{ color: '#1EC130' }}>https://youtu.be/p8illkIFepc</span> <br />   or reach out to us at <span style={{ color: '#8D0AE2' }}>contact@alloysports.com.</span></h4>
            </section>
            <div className='desk-slider'>
                <div className='container-fluid'>
                    <div className='container'>
                        <div className="col-md-12 py-5">
                            <div className="client_img-21">
                                <img width="100%" src={client_4} alt="" />
                                <div className='banner-img-left-box'>
                                    <h1> <span style={{ color: '#1EC130', fontWeight: 600 }}>Download</span>  the <br />  App Now</h1>
                                    <img className='paly-store-2' src={app_img} alt="" />

                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className='responsive-slider'>
                <div className='container-fluid'>
                    <div className="col-md-12">
                        <div>
                            <div className="client_img" style={{ textAlign: 'center', position: 'relative', paddingBottom: '10em' }}>
                                <img src={MobileBanner} alt="" />
                                <h1> <span style={{ color: '#1EC130', fontWeight: 600 }}>Download</span>  the <br />  App Now</h1>
                                <img className='paly-store' src={app_img} alt="" />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <Footer />
        </>
    )
}

export default About