import React, { useEffect, useState } from "react"
import { Carousel } from 'antd';
import Header from "../Header";
import ShareImg from "../../images/Line_duotone.png"
import BullImage1 from "../../images/Rectangle 4029.png"
import BullImage2 from "../../images/Rectangle 4029 (1).png"
import UnitPic from "../../images/Rectangle 4029 (2).png"
import PinP from "../../images/pin_duotone.png"
import input_1 from '../../images/account_sub/Edit_duotone.png'
import Platfrom from "./platform";
import TimeZone from "../../images/Date_today_duotone.png"
import Arrow_purple from "../../images/Vector-purple.png"
import Card_1_img from "../../images/Rectangle 4031.png"
import Card_2_img from "../../images/Rectangle 4027.png"
import { DownOutlined } from '@ant-design/icons';
import { Dropdown, Menu, Progress,Space,Select ,Steps} from 'antd';
import Arrow2 from "../../images/Vector9.png"
import Switch from '@mui/material/Switch';
import Modal from 'react-bootstrap/Modal';
import "./dashboard.css"
import Footer from "../../component/Footer";



const { Option } = Select;
const handleChange = (value) => {
    console.log(`selected ${value}`);
  };

const menu = (
    <Menu
      items={[
        {
          label: <a href="https://www.antgroup.com" className="custom-option-with-icon">Option 01 <img style={{height:"15px",width:"15px",marginLeft:"20px"}} src={input_1} alt="" /> <img style={{height:"15px",width:"15px"}} src={PinP} alt="" /></a>,
          key: '0',
        },
        {
          label: <a href="https://www.aliyun.com" className="custom-option-with-icon">Option 02 <img style={{height:"15px",width:"15px",marginLeft:"20px"}} src={input_1} alt="" /> <img style={{height:"15px",width:"15px"}} src={PinP} alt="" /></a>,
          key: '1',
        },
        
      ]}
    />
  );
  const { Step } = Steps;
const steps = [
  {
    data:"Select up to 3 team characteristics you think are most important to winning a bet",
    title: 'Select Your Winning Factors',
    content: [{data:"Defensive Interceptions",id:"1",bool:false},{data:"Third Down %",id:"2",bool:false},{data:"Points Scored",id:"3",bool:false},{data:"Total Touchdowns",id:"4",bool:false},{data:"Completion %",id:"5",bool:false},{data:"Passer Rating",id:"6",bool:false},{data:"Receiving Yards",id:"7",bool:false},{data:"Rush Yards After Contact",id:"8",bool:false}],
  },
  {
    data:"Choose one filter criteria to further refine your betting strategy. We'll make sure the games and teams to bet on fit your criteria",
    title: 'Add a filter to complete your strategy',
    content:[{data:"Home",id:"9",bool:false},{data:"Away",id:"10",bool:false},{data:"Coming off a loss",id:"11",bool:false},{data:"Coming off a win",id:"12",bool:false},{data:"NFC Opponent",id:"13",bool:false},{data:"AFC Opponent",id:"14",bool:false}],
  },
  {
    data:"we're finding teams in upcoming games for you to bet on thet fit your criteria",
    data2:"Loading  backtested result for your strategy!",
    title: 'See results',
    content:[{data:"Defensive Interceptions"},{data:"Third Down %"},{data:"Points Scored"},{data:"Total Touchdowns"},{data:"Completion %"},{data:"Passer Rating"},{data:"Receiving Yards"},{data:"Rush Yards After Contact"}],
  },
];
const Dashboard = ()=>{
    const [current, setCurrent] = useState(0);
    const [show, setShow] = useState(false);
    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);
    const [checked1, setChecked1] = React.useState(false);
    const handleChange1 = (event) => {
        setChecked1(event.target.checked);
    };

    const [checked2, setchecked2] = React.useState(false);
    const handleChange2 = (event) => {
        setchecked2(event.target.checked);
    };
    const next = () => {
        setCurrent(current + 1);
    };
    const prev = () => {
        setCurrent(current - 1);
    };
    const [modfil,setmodfil] = useState([])
    const [bool,setbool]=useState(false)
    // useEffect(()=>{
    //     setmodfil(modfil)
    // },[modfil])
    const [converter,setconverter] = useState(false)
    const [leftarrwo1,setleftarrwo1] = useState(false)
    const [leftarrwo2,setleftarrwo2] = useState(false)

    return(
        <>
        <Modal dialogClassName="modal-90w" aria-labelledby="example-custom-modal-styling-title" show={show} onHide={handleClose}>
                <Modal.Body>
                    <div className="d-flex align-items-center modal-bet-filter p-4">
                    {current > 0 && (
                        <i onClick={() => prev()} class="fa fa-long-arrow-left" aria-hidden="true"></i>
                    )}
                        <h5  className="m-0">Create Metric With Wizard</h5>
                    </div>
                    <div className="container d-flex flex-column align-items-center justify-content-center">
                        <div className="col-11">
                            <Steps current={current} labelPlacement="vertical">
                                {steps.map((item) => (
                                <Step key={item.title} title={item.title} />
                                ))}
                            </Steps>
                        </div>

                       
                        <div className="col-12 pt-4 metric-filter-boxes-main">
                            <h6>{steps[current].data2}</h6>
                            <p>{steps[current].data}</p>
                        </div>
                        
                        <div className="steps-content-map px-4">
                            <div className="row">
                                {current == 2?
                                <div className="filter_result ">
                                    <h1>50%</h1>
                                    <h5>Please wait! We are creating your strategy...</h5>
                                    <div style={{width:"80%"}}>
                                    <Progress showInfo={false} strokeColor="#00c442" percent={50} />
                                    <div className='col-12 pt-4 pb-3 d-flex align-items-center py-5 justify-content-center w-100 price-btn-outinle-2-2'>
                                        <button style={{width:"fit-content"}}>See result<img className='btn-animation-arrow' src={Arrow2} alt="" /><img className='btn-animation-arrow-2' src={Arrow2} alt="" /></button>
                                    </div>
                                    </div>
                                </div>
                                :
                                steps[current].content.map((data)=>
                                <div onClick={()=>{
                                 modfil.push({"id":data.id})
                                 setmodfil([...modfil])
                                }} className="col-lg-2 mt-2 px-1">
                                    <div className={modfil.length>0?modfil.filter(dataa=>dataa.id==data.id).length>0?"steps-content-text-2":"steps-content-text":"steps-content-text"}>{data.data}</div>
                                </div>
                                )
                                }
                                
                            </div>
                        </div>
                        <div className='col-12 pt-4 pb-3 d-flex align-items-center justify-content-end w-100 price-btn-outinle-2-2'>
                                {current < steps.length - 1 && (
                                    <button style={{width:"fit-content"}} onClick={() => next()}>{current == 1?"Create Result":"Add More Filter"}<img className='btn-animation-arrow' src={Arrow2} alt="" /><img className='btn-animation-arrow-2' src={Arrow2} alt="" /></button>
                                )}
                        </div>
                        {/* {current > 0 && (
                        <Button
                            style={{
                            margin: '0 8px',
                            }}
                            onClick={() => prev()}
                        >
                            Previous
                        </Button>
                        )} */}
                    </div>
                    
                </Modal.Body>
            </Modal>
        <div id="Dashbaord">
            <Header/>
            <div className="row">
                <div className="col-lg-2 p-3 d-flex align-items-center justify-content-center">
                <Dropdown overlay={menu} trigger={['click']}>
                        <a onClick={(e) => e.preventDefault()}>
                        <Space>
                            NFL
                            <DownOutlined />
                        </Space>
                        </a>
                    </Dropdown>
                </div>
                <div className="col-lg-10 p-3 slider-content">
                    <Carousel slidesToShow={4} draggable={true} dots={false} centerMode={false}  arrows={true} infinite={false}>
                        <div className="col-lg-12 p-3">
                            <div className="slide-card">
                                <div className="d-flex align-items-center justify-content-between px-3 pt-3 pb-2 card-date">
                                    <p>Thu 8/04, 5:00 PM</p>
                                    <div>
                                        <img src={ShareImg} alt="" />
                                    </div>
                                </div>
                                <div className="d-flex align-items-center justify-content-between px-3 ">
                                    <div className="card-img-and-name">
                                        <div className="card-image">
                                            <img src={BullImage1} alt="" />
                                        </div>
                                        <h5>JAX</h5>
                                    </div>
                                    <div className="card-score d-flex align-items-center">
                                        <div className="scrore-1">
                                            <h5>+000</h5>
                                        </div>
                                        <div className="scrore-1">
                                            <h5>+000</h5>
                                        </div>
                                    </div>
                                </div>
                                


                                <div className="d-flex align-items-center justify-content-between px-3 pt-2">
                                    <div className="card-img-and-name">
                                        <div className="card-image">
                                            <img src={BullImage2} alt="" />
                                        </div>
                                        <h5>LV</h5>
                                    </div>
                                    <div className="card-score d-flex align-items-center">
                                        <div className="scrore-1">
                                            <h5>+000</h5>
                                        </div>
                                        <div className="scrore-1">
                                            <h5>+000</h5>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-12 p-3">
                            <div className="slide-card">
                                <div className="d-flex align-items-center justify-content-between px-3 pt-3 pb-2 card-date">
                                    <p>Thu 8/04, 5:00 PM</p>
                                    <div>
                                        <img src={ShareImg} alt="" />
                                    </div>
                                </div>
                                <div className="d-flex align-items-center justify-content-between px-3 ">
                                    <div className="card-img-and-name">
                                        <div className="card-image">
                                            <img src={BullImage1} alt="" />
                                        </div>
                                        <h5>JAX</h5>
                                    </div>
                                    <div className="card-score d-flex align-items-center">
                                        <div className="scrore-1">
                                            <h5>+000</h5>
                                        </div>
                                        <div className="scrore-1">
                                            <h5>+000</h5>
                                        </div>
                                    </div>
                                </div>
                                


                                <div className="d-flex align-items-center justify-content-between px-3 pt-2">
                                    <div className="card-img-and-name">
                                        <div className="card-image">
                                            <img src={BullImage2} alt="" />
                                        </div>
                                        <h5>LV</h5>
                                    </div>
                                    <div className="card-score d-flex align-items-center">
                                        <div className="scrore-1">
                                            <h5>+000</h5>
                                        </div>
                                        <div className="scrore-1">
                                            <h5>+000</h5>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-12 p-3">
                            <div className="slide-card">
                                <div className="d-flex align-items-center justify-content-between px-3 pt-3 pb-2 card-date">
                                    <p>Thu 8/04, 5:00 PM</p>
                                    <div>
                                        <img src={ShareImg} alt="" />
                                    </div>
                                </div>
                                <div className="d-flex align-items-center justify-content-between px-3 ">
                                    <div className="card-img-and-name">
                                        <div className="card-image">
                                            <img src={BullImage1} alt="" />
                                        </div>
                                        <h5>JAX</h5>
                                    </div>
                                    <div className="card-score d-flex align-items-center">
                                        <div className="scrore-1">
                                            <h5>+000</h5>
                                        </div>
                                        <div className="scrore-1">
                                            <h5>+000</h5>
                                        </div>
                                    </div>
                                </div>
                                


                                <div className="d-flex align-items-center justify-content-between px-3 pt-2">
                                    <div className="card-img-and-name">
                                        <div className="card-image">
                                            <img src={BullImage2} alt="" />
                                        </div>
                                        <h5>LV</h5>
                                    </div>
                                    <div className="card-score d-flex align-items-center">
                                        <div className="scrore-1">
                                            <h5>+000</h5>
                                        </div>
                                        <div className="scrore-1">
                                            <h5>+000</h5>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-12 p-3">
                            <div className="slide-card">
                                <div className="d-flex align-items-center justify-content-between px-3 pt-3 pb-2 card-date">
                                    <p>Thu 8/04, 5:00 PM</p>
                                    <div>
                                        <img src={ShareImg} alt="" />
                                    </div>
                                </div>
                                <div className="d-flex align-items-center justify-content-between px-3 ">
                                    <div className="card-img-and-name">
                                        <div className="card-image">
                                            <img src={BullImage1} alt="" />
                                        </div>
                                        <h5>JAX</h5>
                                    </div>
                                    <div className="card-score d-flex align-items-center">
                                        <div className="scrore-1">
                                            <h5>+000</h5>
                                        </div>
                                        <div className="scrore-1">
                                            <h5>+000</h5>
                                        </div>
                                    </div>
                                </div>
                                


                                <div className="d-flex align-items-center justify-content-between px-3 pt-2">
                                    <div className="card-img-and-name">
                                        <div className="card-image">
                                            <img src={BullImage2} alt="" />
                                        </div>
                                        <h5>LV</h5>
                                    </div>
                                    <div className="card-score d-flex align-items-center">
                                        <div className="scrore-1">
                                            <h5>+000</h5>
                                        </div>
                                        <div className="scrore-1">
                                            <h5>+000</h5>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-12 p-3">
                            <div className="slide-card">
                                <div className="d-flex align-items-center justify-content-between px-3 pt-3 pb-2 card-date">
                                    <p>Thu 8/04, 5:00 PM</p>
                                    <div>
                                        <img src={ShareImg} alt="" />
                                    </div>
                                </div>
                                <div className="d-flex align-items-center justify-content-between px-3 ">
                                    <div className="card-img-and-name">
                                        <div className="card-image">
                                            <img src={BullImage1} alt="" />
                                        </div>
                                        <h5>JAX</h5>
                                    </div>
                                    <div className="card-score d-flex align-items-center">
                                        <div className="scrore-1">
                                            <h5>+000</h5>
                                        </div>
                                        <div className="scrore-1">
                                            <h5>+000</h5>
                                        </div>
                                    </div>
                                </div>
                                


                                <div className="d-flex align-items-center justify-content-between px-3 pt-2">
                                    <div className="card-img-and-name">
                                        <div className="card-image">
                                            <img src={BullImage2} alt="" />
                                        </div>
                                        <h5>LV</h5>
                                    </div>
                                    <div className="card-score d-flex align-items-center">
                                        <div className="scrore-1">
                                            <h5>+000</h5>
                                        </div>
                                        <div className="scrore-1">
                                            <h5>+000</h5>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-12 p-3">
                            <div className="slide-card">
                                <div className="d-flex align-items-center justify-content-between px-3 pt-3 pb-2 card-date">
                                    <p>Thu 8/04, 5:00 PM</p>
                                    <div>
                                        <img src={ShareImg} alt="" />
                                    </div>
                                </div>
                                <div className="d-flex align-items-center justify-content-between px-3 ">
                                    <div className="card-img-and-name">
                                        <div className="card-image">
                                            <img src={BullImage1} alt="" />
                                        </div>
                                        <h5>JAX</h5>
                                    </div>
                                    <div className="card-score d-flex align-items-center">
                                        <div className="scrore-1">
                                            <h5>+000</h5>
                                        </div>
                                        <div className="scrore-1">
                                            <h5>+000</h5>
                                        </div>
                                    </div>
                                </div>
                                


                                <div className="d-flex align-items-center justify-content-between px-3 pt-2">
                                    <div className="card-img-and-name">
                                        <div className="card-image">
                                            <img src={BullImage2} alt="" />
                                        </div>
                                        <h5>LV</h5>
                                    </div>
                                    <div className="card-score d-flex align-items-center">
                                        <div className="scrore-1">
                                            <h5>+000</h5>
                                        </div>
                                        <div className="scrore-1">
                                            <h5>+000</h5>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-12 p-3">
                            <div className="slide-card">
                                <div className="d-flex align-items-center justify-content-between px-3 pt-3 pb-2 card-date">
                                    <p>Thu 8/04, 5:00 PM</p>
                                    <div>
                                        <img src={ShareImg} alt="" />
                                    </div>
                                </div>
                                <div className="d-flex align-items-center justify-content-between px-3 ">
                                    <div className="card-img-and-name">
                                        <div className="card-image">
                                            <img src={BullImage1} alt="" />
                                        </div>
                                        <h5>JAX</h5>
                                    </div>
                                    <div className="card-score d-flex align-items-center">
                                        <div className="scrore-1">
                                            <h5>+000</h5>
                                        </div>
                                        <div className="scrore-1">
                                            <h5>+000</h5>
                                        </div>
                                    </div>
                                </div>
                                


                                <div className="d-flex align-items-center justify-content-between px-3 pt-2">
                                    <div className="card-img-and-name">
                                        <div className="card-image">
                                            <img src={BullImage2} alt="" />
                                        </div>
                                        <h5>LV</h5>
                                    </div>
                                    <div className="card-score d-flex align-items-center">
                                        <div className="scrore-1">
                                            <h5>+000</h5>
                                        </div>
                                        <div className="scrore-1">
                                            <h5>+000</h5>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-12 p-3">
                            <div className="slide-card">
                                <div className="d-flex align-items-center justify-content-between px-3 pt-3 pb-2 card-date">
                                    <p>Thu 8/04, 5:00 PM</p>
                                    <div>
                                        <img src={ShareImg} alt="" />
                                    </div>
                                </div>
                                <div className="d-flex align-items-center justify-content-between px-3 ">
                                    <div className="card-img-and-name">
                                        <div className="card-image">
                                            <img src={BullImage1} alt="" />
                                        </div>
                                        <h5>JAX</h5>
                                    </div>
                                    <div className="card-score d-flex align-items-center">
                                        <div className="scrore-1">
                                            <h5>+000</h5>
                                        </div>
                                        <div className="scrore-1">
                                            <h5>+000</h5>
                                        </div>
                                    </div>
                                </div>
                                


                                <div className="d-flex align-items-center justify-content-between px-3 pt-2">
                                    <div className="card-img-and-name">
                                        <div className="card-image">
                                            <img src={BullImage2} alt="" />
                                        </div>
                                        <h5>LV</h5>
                                    </div>
                                    <div className="card-score d-flex align-items-center">
                                        <div className="scrore-1">
                                            <h5>+000</h5>
                                        </div>
                                        <div className="scrore-1">
                                            <h5>+000</h5>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-12 p-3">
                            <div className="slide-card">
                                <div className="d-flex align-items-center justify-content-between px-3 pt-3 pb-2 card-date">
                                    <p>Thu 8/04, 5:00 PM</p>
                                    <div>
                                        <img src={ShareImg} alt="" />
                                    </div>
                                </div>
                                <div className="d-flex align-items-center justify-content-between px-3 ">
                                    <div className="card-img-and-name">
                                        <div className="card-image">
                                            <img src={BullImage1} alt="" />
                                        </div>
                                        <h5>JAX</h5>
                                    </div>
                                    <div className="card-score d-flex align-items-center">
                                        <div className="scrore-1">
                                            <h5>+000</h5>
                                        </div>
                                        <div className="scrore-1">
                                            <h5>+000</h5>
                                        </div>
                                    </div>
                                </div>
                                


                                <div className="d-flex align-items-center justify-content-between px-3 pt-2">
                                    <div className="card-img-and-name">
                                        <div className="card-image">
                                            <img src={BullImage2} alt="" />
                                        </div>
                                        <h5>LV</h5>
                                    </div>
                                    <div className="card-score d-flex align-items-center">
                                        <div className="scrore-1">
                                            <h5>+000</h5>
                                        </div>
                                        <div className="scrore-1">
                                            <h5>+000</h5>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </Carousel>
                </div>

            </div>
            <div className="container-fluid px-4">
            <div className="row px-3">
                <div className="switcher-dashboard p-1 ">
                    <div style={{transition:"0.3s all",transform:converter==false?"translate(168px)":"translate(0px)"}} className="purple-dash-bacr-btn">
                        {converter==false?<h5>platform</h5>:<h5>Dashboard</h5>}
                    </div>
                    <div style={{cursor:"pointer"}} onClick={()=>{setconverter(true)}} className="col-6 dash-bacr-btn">
                        <h5>Dashboard</h5>
                    </div>
                    <div style={{cursor:"pointer"}} onClick={()=>{setconverter(false)}} className="col-6 dash-bacr-btn">
                        <h5>platform</h5> 
                    </div>
                </div>
            </div>
            </div>
            <div className="container-fluid">
                <div className="d-flex align-items-center justify-content-between px-3 py-5 hello-user-name">
                    <h1>Welcome, Rechel!</h1>
                    <div>
                    <Select
                    defaultValue="Select Sports"
                    style={{
                        width: 270,
                    }}
                    onChange={handleChange}
                    >
                    <Option value="Select Sports">Select Sports</Option>
                    <Option value="lucy">Lucy</Option>
                    <Option value="Yiminghe">yiminghe</Option>
                    </Select>
                    </div>
                </div>
            </div>

            {converter == true?<>
            <div className="container">
                <div className='col-12 d-flex align-items-center justify-content-between w-100 price-btn-outinle-2-2'>
                    <div className="col-6">
                    <button onClick={handleShow}>Create Metric With Wizard<img className='btn-animation-arrow' src={Arrow2} alt="" /><img className='btn-animation-arrow-2' src={Arrow2} alt="" /></button>
                    </div>
                    <div className="col-6">
                    <button >Create New Strategy<img className='btn-animation-arrow' src={Arrow2} alt="" /><img className='btn-animation-arrow-2' src={Arrow2} alt="" /></button>
                    </div>
                </div>

                <div className="your-bet-sec p-4">
                    <div>
                    <h2 className="m-0">Your Games to Bet</h2>
                    </div>
                    <div className="d-flex align-items-center justify-content-between">
                        <div className="d-flex align-items-center"><h5 className="mr-5 mb-0">My Strategy 01</h5><DownOutlined /></div>
                            <div>

                                <div className="d">
                                <span style={{ color: 'black ',fontWeight:"500"  }}>Spread</span>
                                <Switch
                                    checked={checked1}
                                    onChange={handleChange1}
                                    inputProps={{ 'aria-label': 'controlled' }}
                                />
                                <span style={{ color: 'black',fontWeight:"500" }}>Moneyline</span>

                            </div>
                        </div>
                    </div>
                </div>

                
            </div>
            <div className="container">
                <div id={leftarrwo1 == false?"DownBoard2":"DownBoard"} className="col-12">
                    <Carousel afterChange={(index)=>{index == 1?setleftarrwo1(true):setleftarrwo1(false)}} slidesToShow={3} draggable={true} dots={false}  arrows={true} infinite={false}>
                        <div className="col-lg-12 p-3">
                            <div className="slide-card">
                                <div className="d-flex align-items-center justify-content-between px-3 pt-3 pb-2 card-date">
                                    <p>Thu 8/04, 5:00 PM</p>
                                    <div >
                                        <img src={ShareImg} alt="" />
                                        <img className="ml-3" src={PinP} alt="" />
                                    </div>
                                </div>
                                <div className="d-flex flex-column" style={{paddingInline:'30px'}}>
                                    <div className="d-flex align-items-center justify-content-space">
                                    <div className="strategy-section-1">
                                        <div className="strategy-section-1-pic">
                                            <img src={UnitPic} alt="" />
                                        </div>
                                        <h6>LV</h6>
                                    </div>
                                    <div className="strategy-section-2 px-3">
                                        <p>Consensus</p>
                                        <p className="Consensus-rate">+3.5</p>
                                    </div>
                                    <div className="strategy-section-1-1">
                                        <div className="strategy-section-1-pic">
                                            <img src={UnitPic} alt="" />
                                        </div>
                                        <h6>JAX</h6>
                                    </div>
                                    </div>
                                    <div>
                                    <hr className="my-2"/> 
                                    </div>
                                    <div className="d-flex align-items-center justify-content-between starategy-card-time-zone py-2">
                                        <p>Historical Performance</p>
                                        <div className="Date-format">
                                        Oct/2021 <img src={TimeZone} alt="" />
                                        </div>
                                    </div> 
                                    <div className="d-flex align-items-center justify-content-around pt-3">
                                        <div className="strategy-section-2">
                                            <p>ROI</p>
                                            <p style={{color:"#25408F"}} className="Consensus-rate">0.0%</p>
                                        </div>
                                        <div className="strategy-section-2">
                                            <p>Win%</p>
                                            <p style={{color:"#25408F"}} className="Consensus-rate">00-00-0</p>
                                        </div>
                                        <div className="strategy-section-2">
                                            <p>Earning</p>
                                            <p style={{color:"#25408F"}} className="Consensus-rate">$000.00</p>
                                        </div>
                                    </div>
                                    <div className="py-3 d-flex align-items-center justify-content-center">
                                        <button className="strategy-last-btn">Close Favourites</button>
                                    </div>
                                </div>
                               
                            </div>
                        </div>

                        <div className="col-lg-12 p-3">
                            <div className="slide-card">
                                <div className="d-flex align-items-center justify-content-between px-3 pt-3 pb-2 card-date">
                                    <p>Thu 8/04, 5:00 PM</p>
                                    <div >
                                        <img src={ShareImg} alt="" />
                                        <img className="ml-3" src={PinP} alt="" />
                                    </div>
                                </div>
                                <div className="d-flex flex-column" style={{paddingInline:'30px'}}>
                                    <div className="d-flex align-items-center justify-content-space">
                                    <div className="strategy-section-1">
                                        <div className="strategy-section-1-pic">
                                            <img src={UnitPic} alt="" />
                                        </div>
                                        <h6>LV</h6>
                                    </div>
                                    <div className="strategy-section-2 px-3">
                                        <p>Consensus</p>
                                        <p className="Consensus-rate">+3.5</p>
                                    </div>
                                    <div className="strategy-section-1-1">
                                        <div className="strategy-section-1-pic">
                                            <img src={UnitPic} alt="" />
                                        </div>
                                        <h6>JAX</h6>
                                    </div>
                                    </div>
                                    <div>
                                    <hr className="my-2"/> 
                                    </div>
                                    <div className="d-flex align-items-center justify-content-between starategy-card-time-zone py-2">
                                        <p>Historical Performance</p>
                                        <div className="Date-format">
                                        Oct/2021 <img src={TimeZone} alt="" />
                                        </div>
                                    </div> 
                                    <div className="d-flex align-items-center justify-content-around pt-3">
                                        <div className="strategy-section-2">
                                            <p>ROI</p>
                                            <p style={{color:"#25408F"}} className="Consensus-rate">0.0%</p>
                                        </div>
                                        <div className="strategy-section-2">
                                            <p>Win%</p>
                                            <p style={{color:"#25408F"}} className="Consensus-rate">00-00-0</p>
                                        </div>
                                        <div className="strategy-section-2">
                                            <p>Earning</p>
                                            <p style={{color:"#25408F"}} className="Consensus-rate">$000.00</p>
                                        </div>
                                    </div>
                                    <div className="py-3 d-flex align-items-center justify-content-center">
                                        <button style={{background:"#C11E45"}} className="strategy-last-btn">Close Underdogs</button>
                                    </div>
                                </div>
                               
                            </div>
                        </div>

                        <div className="col-lg-12 p-3">
                            <div className="slide-card">
                                <div className="d-flex align-items-center justify-content-between px-3 pt-3 pb-2 card-date">
                                    <p>Thu 8/04, 5:00 PM</p>
                                    <div >
                                        <img src={ShareImg} alt="" />
                                        <img className="ml-3" src={PinP} alt="" />
                                    </div>
                                </div>
                                <div className="d-flex flex-column" style={{paddingInline:'30px'}}>
                                    <div className="d-flex align-items-center justify-content-space">
                                    <div className="strategy-section-1">
                                        <div className="strategy-section-1-pic">
                                            <img src={UnitPic} alt="" />
                                        </div>
                                        <h6>LV</h6>
                                    </div>
                                    <div className="strategy-section-2 px-3">
                                        <p>Consensus</p>
                                        <p className="Consensus-rate">+3.5</p>
                                    </div>
                                    <div className="strategy-section-1-1">
                                        <div className="strategy-section-1-pic">
                                            <img src={UnitPic} alt="" />
                                        </div>
                                        <h6>JAX</h6>
                                    </div>
                                    </div>
                                    <div>
                                    <hr className="my-2"/> 
                                    </div>
                                    <div className="d-flex align-items-center justify-content-between starategy-card-time-zone py-2">
                                        <p>Historical Performance</p>
                                        <div className="Date-format">
                                        Oct/2021 <img src={TimeZone} alt="" />
                                        </div>
                                    </div> 
                                    <div className="d-flex align-items-center justify-content-around pt-3">
                                        <div className="strategy-section-2">
                                            <p>ROI</p>
                                            <p style={{color:"#25408F"}} className="Consensus-rate">0.0%</p>
                                        </div>
                                        <div className="strategy-section-2">
                                            <p>Win%</p>
                                            <p style={{color:"#25408F"}} className="Consensus-rate">00-00-0</p>
                                        </div>
                                        <div className="strategy-section-2">
                                            <p>Earning</p>
                                            <p style={{color:"#25408F"}} className="Consensus-rate">$000.00</p>
                                        </div>
                                    </div>
                                    <div className="py-3 d-flex align-items-center justify-content-center">
                                        <button className="strategy-last-btn">Close Favourites</button>
                                    </div>
                                </div>
                               
                            </div>
                        </div>

                        <div className="col-lg-12 p-3">
                            <div className="slide-card">
                                <div className="d-flex align-items-center justify-content-between px-3 pt-3 pb-2 card-date">
                                    <p>Thu 8/04, 5:00 PM</p>
                                    <div >
                                        <img src={ShareImg} alt="" />
                                        <img className="ml-3" src={PinP} alt="" />
                                    </div>
                                </div>
                                <div className="d-flex flex-column" style={{paddingInline:'30px'}}>
                                    <div className="d-flex align-items-center justify-content-space">
                                    <div className="strategy-section-1">
                                        <div className="strategy-section-1-pic">
                                            <img src={UnitPic} alt="" />
                                        </div>
                                        <h6>LV</h6>
                                    </div>
                                    <div className="strategy-section-2 px-3">
                                        <p>Consensus</p>
                                        <p className="Consensus-rate">+3.5</p>
                                    </div>
                                    <div className="strategy-section-1-1">
                                        <div className="strategy-section-1-pic">
                                            <img src={UnitPic} alt="" />
                                        </div>
                                        <h6>JAX</h6>
                                    </div>
                                    </div>
                                    <div>
                                    <hr className="my-2"/> 
                                    </div>
                                    <div className="d-flex align-items-center justify-content-between starategy-card-time-zone py-2">
                                        <p>Historical Performance</p>
                                        <div className="Date-format">
                                        Oct/2021 <img src={TimeZone} alt="" />
                                        </div>
                                    </div> 
                                    <div className="d-flex align-items-center justify-content-around pt-3">
                                        <div className="strategy-section-2">
                                            <p>ROI</p>
                                            <p style={{color:"#25408F"}} className="Consensus-rate">0.0%</p>
                                        </div>
                                        <div className="strategy-section-2">
                                            <p>Win%</p>
                                            <p style={{color:"#25408F"}} className="Consensus-rate">00-00-0</p>
                                        </div>
                                        <div className="strategy-section-2">
                                            <p>Earning</p>
                                            <p style={{color:"#25408F"}} className="Consensus-rate">$000.00</p>
                                        </div>
                                    </div>
                                    <div className="py-3 d-flex align-items-center justify-content-center">
                                        <button className="strategy-last-btn">Close Favourites</button>
                                    </div>
                                </div>
                               
                            </div>
                        </div>
                    </Carousel>
                </div>

                <div className="d-flex align-items-center justify-content-between px-4">
                        <div className="d-flex align-items-center"><h5 className="mr-5 mb-0">My Strategy 01</h5><DownOutlined /></div>
                            <div>

                                <div className="d">
                                <span style={{ color: 'black ',fontWeight:"500"  }}>Spread</span>
                                <Switch
                                    checked={checked2}
                                    onChange={handleChange2}
                                    inputProps={{ 'aria-label': 'controlled' }}
                                />
                                <span style={{ color: 'black',fontWeight:"500" }}>Moneyline</span>

                            </div>
                        </div>
                    </div>




                    <div id={leftarrwo2 == false?"DownBoard4":"DownBoard3"} className="col-12">
                    <Carousel afterChange={(index)=>{index == 1?setleftarrwo2(true):setleftarrwo2(false)}} slidesToShow={3} draggable={true} dots={false}  arrows={true} infinite={false}>
                        <div className="col-lg-12 p-3">
                            <div className="slide-card">
                                <div className="d-flex align-items-center justify-content-between px-3 pt-3 pb-2 card-date">
                                    <p>Thu 8/04, 5:00 PM</p>
                                    <div >
                                        <img src={ShareImg} alt="" />
                                        <img className="ml-3" src={PinP} alt="" />
                                    </div>
                                </div>
                                <div className="d-flex flex-column" style={{paddingInline:'30px'}}>
                                    <div className="d-flex align-items-center justify-content-space">
                                    <div className="strategy-section-1">
                                        <div className="strategy-section-1-pic">
                                            <img src={UnitPic} alt="" />
                                        </div>
                                        <h6>LV</h6>
                                    </div>
                                    <div className="strategy-section-2 px-3">
                                        <p>Consensus</p>
                                        <p className="Consensus-rate">+3.5</p>
                                    </div>
                                    <div className="strategy-section-1-1">
                                        <div className="strategy-section-1-pic">
                                            <img src={UnitPic} alt="" />
                                        </div>
                                        <h6>JAX</h6>
                                    </div>
                                    </div>
                                    <div>
                                    <hr className="my-2"/> 
                                    </div>
                                    <div className="d-flex align-items-center justify-content-between starategy-card-time-zone py-2">
                                        <p>Historical Performance</p>
                                        <div className="Date-format">
                                        Oct/2021 <img src={TimeZone} alt="" />
                                        </div>
                                    </div> 
                                    <div className="d-flex align-items-center justify-content-around pt-3">
                                        <div className="strategy-section-2">
                                            <p>ROI</p>
                                            <p style={{color:"#25408F"}} className="Consensus-rate">0.0%</p>
                                        </div>
                                        <div className="strategy-section-2">
                                            <p>Win%</p>
                                            <p style={{color:"#25408F"}} className="Consensus-rate">00-00-0</p>
                                        </div>
                                        <div className="strategy-section-2">
                                            <p>Earning</p>
                                            <p style={{color:"#25408F"}} className="Consensus-rate">$000.00</p>
                                        </div>
                                    </div>
                                    <div className="py-3 d-flex align-items-center justify-content-center">
                                        <button className="strategy-last-btn">Close Favourites</button>
                                    </div>
                                </div>
                               
                            </div>
                        </div>

                        <div className="col-lg-12 p-3">
                            <div className="slide-card">
                                <div className="d-flex align-items-center justify-content-between px-3 pt-3 pb-2 card-date">
                                    <p>Thu 8/04, 5:00 PM</p>
                                    <div >
                                        <img src={ShareImg} alt="" />
                                        <img className="ml-3" src={PinP} alt="" />
                                    </div>
                                </div>
                                <div className="d-flex flex-column" style={{paddingInline:'30px'}}>
                                    <div className="d-flex align-items-center justify-content-space">
                                    <div className="strategy-section-1">
                                        <div className="strategy-section-1-pic">
                                            <img src={UnitPic} alt="" />
                                        </div>
                                        <h6>LV</h6>
                                    </div>
                                    <div className="strategy-section-2 px-3">
                                        <p>Consensus</p>
                                        <p className="Consensus-rate">+3.5</p>
                                    </div>
                                    <div className="strategy-section-1-1">
                                        <div className="strategy-section-1-pic">
                                            <img src={UnitPic} alt="" />
                                        </div>
                                        <h6>JAX</h6>
                                    </div>
                                    </div>
                                    <div>
                                    <hr className="my-2"/> 
                                    </div>
                                    <div className="d-flex align-items-center justify-content-between starategy-card-time-zone py-2">
                                        <p>Historical Performance</p>
                                        <div className="Date-format">
                                        Oct/2021 <img src={TimeZone} alt="" />
                                        </div>
                                    </div> 
                                    <div className="d-flex align-items-center justify-content-around pt-3">
                                        <div className="strategy-section-2">
                                            <p>ROI</p>
                                            <p style={{color:"#25408F"}} className="Consensus-rate">0.0%</p>
                                        </div>
                                        <div className="strategy-section-2">
                                            <p>Win%</p>
                                            <p style={{color:"#25408F"}} className="Consensus-rate">00-00-0</p>
                                        </div>
                                        <div className="strategy-section-2">
                                            <p>Earning</p>
                                            <p style={{color:"#25408F"}} className="Consensus-rate">$000.00</p>
                                        </div>
                                    </div>
                                    <div className="py-3 d-flex align-items-center justify-content-center">
                                        <button style={{background:"#C11E45"}} className="strategy-last-btn">Close Underdogs</button>
                                    </div>
                                </div>
                               
                            </div>
                        </div>

                        <div className="col-lg-12 p-3">
                            <div className="slide-card">
                                <div className="d-flex align-items-center justify-content-between px-3 pt-3 pb-2 card-date">
                                    <p>Thu 8/04, 5:00 PM</p>
                                    <div >
                                        <img src={ShareImg} alt="" />
                                        <img className="ml-3" src={PinP} alt="" />
                                    </div>
                                </div>
                                <div className="d-flex flex-column" style={{paddingInline:'30px'}}>
                                    <div className="d-flex align-items-center justify-content-space">
                                    <div className="strategy-section-1">
                                        <div className="strategy-section-1-pic">
                                            <img src={UnitPic} alt="" />
                                        </div>
                                        <h6>LV</h6>
                                    </div>
                                    <div className="strategy-section-2 px-3">
                                        <p>Consensus</p>
                                        <p className="Consensus-rate">+3.5</p>
                                    </div>
                                    <div className="strategy-section-1-1">
                                        <div className="strategy-section-1-pic">
                                            <img src={UnitPic} alt="" />
                                        </div>
                                        <h6>JAX</h6>
                                    </div>
                                    </div>
                                    <div>
                                    <hr className="my-2"/> 
                                    </div>
                                    <div className="d-flex align-items-center justify-content-between starategy-card-time-zone py-2">
                                        <p>Historical Performance</p>
                                        <div className="Date-format">
                                        Oct/2021 <img src={TimeZone} alt="" />
                                        </div>
                                    </div> 
                                    <div className="d-flex align-items-center justify-content-around pt-3">
                                        <div className="strategy-section-2">
                                            <p>ROI</p>
                                            <p style={{color:"#25408F"}} className="Consensus-rate">0.0%</p>
                                        </div>
                                        <div className="strategy-section-2">
                                            <p>Win%</p>
                                            <p style={{color:"#25408F"}} className="Consensus-rate">00-00-0</p>
                                        </div>
                                        <div className="strategy-section-2">
                                            <p>Earning</p>
                                            <p style={{color:"#25408F"}} className="Consensus-rate">$000.00</p>
                                        </div>
                                    </div>
                                    <div className="py-3 d-flex align-items-center justify-content-center">
                                        <button className="strategy-last-btn">Close Favourites</button>
                                    </div>
                                </div>
                               
                            </div>
                        </div>

                        <div className="col-lg-12 p-3">
                            <div className="slide-card">
                                <div className="d-flex align-items-center justify-content-between px-3 pt-3 pb-2 card-date">
                                    <p>Thu 8/04, 5:00 PM</p>
                                    <div >
                                        <img src={ShareImg} alt="" />
                                        <img className="ml-3" src={PinP} alt="" />
                                    </div>
                                </div>
                                <div className="d-flex flex-column" style={{paddingInline:'30px'}}>
                                    <div className="d-flex align-items-center justify-content-space">
                                    <div className="strategy-section-1">
                                        <div className="strategy-section-1-pic">
                                            <img src={UnitPic} alt="" />
                                        </div>
                                        <h6>LV</h6>
                                    </div>
                                    <div className="strategy-section-2 px-3">
                                        <p>Consensus</p>
                                        <p className="Consensus-rate">+3.5</p>
                                    </div>
                                    <div className="strategy-section-1-1">
                                        <div className="strategy-section-1-pic">
                                            <img src={UnitPic} alt="" />
                                        </div>
                                        <h6>JAX</h6>
                                    </div>
                                    </div>
                                    <div>
                                    <hr className="my-2"/> 
                                    </div>
                                    <div className="d-flex align-items-center justify-content-between starategy-card-time-zone py-2">
                                        <p>Historical Performance</p>
                                        <div className="Date-format">
                                        Oct/2021 <img src={TimeZone} alt="" />
                                        </div>
                                    </div> 
                                    <div className="d-flex align-items-center justify-content-around pt-3">
                                        <div className="strategy-section-2">
                                            <p>ROI</p>
                                            <p style={{color:"#25408F"}} className="Consensus-rate">0.0%</p>
                                        </div>
                                        <div className="strategy-section-2">
                                            <p>Win%</p>
                                            <p style={{color:"#25408F"}} className="Consensus-rate">00-00-0</p>
                                        </div>
                                        <div className="strategy-section-2">
                                            <p>Earning</p>
                                            <p style={{color:"#25408F"}} className="Consensus-rate">$000.00</p>
                                        </div>
                                    </div>
                                    <div className="py-3 d-flex align-items-center justify-content-center">
                                        <button className="strategy-last-btn">Close Favourites</button>
                                    </div>
                                </div>
                               
                            </div>
                        </div>
                    </Carousel>
                </div>

                <div className="container mt-5 mb-5">
                <div className="row" id="scroll_cards">
                    <div className="col-md-5 pl-5">
                        <div className="scroll_heading">
                            <h2>Latest Content
                                & Analysis</h2>
                            <div className='d-flex align-items-center justify-content-start price-btn m-0 p-0'>
                                <button className='scroll_card-button'>VIEW MORE <img className='btn-animation-arrow' src={Arrow_purple} alt="" /><img className='btn-animation-arrow-2' src={Arrow_purple} alt="" /></button>
                            </div>
                        </div>
                    </div>
                    <div className="col-md-7">
                        <div className="row over" style={{ height: "450px", overflowY: "auto" }}>
                            <div className="col-md-6">
                                <div className="card1" style={{ marginTop: '22px' }}>
                                    <div>
                                        <img style={{ width: '100%' }} src={Card_1_img} alt="" />
                                    </div>
                                    <div className="card_1-details">
                                        <div className='span_1'>
                                            <span>BETTING EDUCATION</span>
                                        </div>
                                        <div className='heading_1'>
                                            <h6>The Top USA Sports  Bookmakers for Winning Bets</h6>
                                        </div>
                                        <div className='span_2'>
                                            <span>Thu 7/19, 1:51 PM</span>
                                        </div>
                                    </div>
                                </div>
                                <div className="card1" style={{ marginTop: '22px' }}>
                                    <div>
                                        <img style={{ width: '100%' }} src={Card_2_img} alt="" />
                                    </div>
                                    <div className="card_1-details">
                                        <div className='span_1'>
                                            <span>BETTING EDUCATION</span>
                                        </div>
                                        <div className='heading_1'>
                                            <h6>The Top USA Sports <br /> Bookmakers for Winning <br /> Bets</h6>
                                        </div>
                                        <div className='span_2'>
                                            <span>Thu 7/19, 1:51 PM</span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="col-md-6">
                                <div className="card1" style={{ marginTop: '100px' }}>
                                    <div>
                                        <img style={{ width: '100%' }} src={Card_1_img} alt="" />
                                    </div>
                                    <div className="card_1-details">
                                        <div className='span_1'>
                                            <span>BETTING EDUCATION</span>
                                        </div>
                                        <div className='heading_1'>
                                            <h6>The Top USA Sports <br /> Bookmakers for Winning <br /> Bets</h6>
                                        </div>
                                        <div className='span_2'>
                                            <span>Thu 7/19, 1:51 PM</span>
                                        </div>
                                    </div>
                                </div>
                                <div className="card1 mb-4" style={{ marginTop: '22px' }}>
                                    <div>
                                        <img style={{ width: '100%' }} src={Card_1_img} alt="" />
                                    </div>
                                    <div className="card_1-details">
                                        <div className='span_1'>
                                            <span>BETTING EDUCATION</span>
                                        </div>
                                        <div className='heading_1'>
                                            <h6>The Top USA Sports <br /> Bookmakers for Winning <br /> Bets</h6>
                                        </div>
                                        <div className='span_2'>
                                            <span>Thu 7/19, 1:51 PM</span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                {/* </section> */}
                </div>       
            </div>
            </>:
            <div className="px-4">
            <Platfrom/>
            </div>
            }



            <Footer/>
        </div>
        </>
    )
}

export default Dashboard;