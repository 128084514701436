import React, { useState } from "react"
import "./platform.css"
import Arrow2 from "../../images/Vector9.png"
import { Dropdown, Menu, Space, } from 'antd';
import { Line } from '@ant-design/plots';
import Save from "../../images/Save.png"
import TimeZone from "../../images/Date_today_duotone.png"
import {ArrowLeftOutlined} from "@ant-design/icons"
import Sharelarge from "../../images/Line_duotone (1).png"
import Green1 from "../../images/green1.png"
import Buildsm from "../../images/buildsm.png"
import Arrow from "../../images/Vector9.png"
import Bet from "../../images/bet.png"
import Info_sign from "../../images/Info_duotone_line.png"
import Green2 from "../../images/green2.png"
import red1 from "../../images/red1.png"
import red2 from "../../images/red2.png"
import Modal from 'react-bootstrap/Modal';
import { Button, message, Steps ,Progress, Tooltip,Slider, Switch ,Select} from 'antd';
const { Step } = Steps;
const steps = [
    {
      data:"Select up to 3 team characteristics you think are most important to winning a bet",
      title: 'Select Your Winning Factors',
      content: [{data:"Defensive Interceptions",id:"1",bool:false},{data:"Third Down %",id:"2",bool:false},{data:"Points Scored",id:"3",bool:false},{data:"Total Touchdowns",id:"4",bool:false},{data:"Completion %",id:"5",bool:false},{data:"Passer Rating",id:"6",bool:false},{data:"Receiving Yards",id:"7",bool:false},{data:"Rush Yards After Contact",id:"8",bool:false}],
    },
    {
      data:"Choose one filter criteria to further refine your betting strategy. We'll make sure the games and teams to bet on fit your criteria",
      title: 'Add a filter to complete your strategy',
      content:[{data:"Home",id:"9",bool:false},{data:"Away",id:"10",bool:false},{data:"Coming off a loss",id:"11",bool:false},{data:"Coming off a win",id:"12",bool:false},{data:"NFC Opponent",id:"13",bool:false},{data:"AFC Opponent",id:"14",bool:false}],
    },
    {
      data:"we're finding teams in upcoming games for you to bet on thet fit your criteria",
      data2:"Loading  backtested result for your strategy!",
      title: 'See results',
      content:[{data:"Defensive Interceptions"},{data:"Third Down %"},{data:"Points Scored"},{data:"Total Touchdowns"},{data:"Completion %"},{data:"Passer Rating"},{data:"Receiving Yards"},{data:"Rush Yards After Contact"}],
    },
  ];
    const { Option } = Select;
    const handleChange = (value) => {
        console.log(`selected ${value}`);
    };
    const data = [
        {
          year: 'Oct 21',
          money: "$3",
        },
        {
          year: 'Nov 21',
          money: "$4",
        },
        {
          year: 'Dec 21',
          money: "$3",
        },
        {
          year: 'Jan 22',
          money: "$5",
        },
        {
          year: 'Feb 22',
          money: "$4",
        },
        {
          year: 'Mar 22',
          money: "$6",
        },
        {
          year: 'Apr 22',
          money: "$7",
        },
        {
          year: 'May 22',
          money: "$9",
        },
        {
          year: 'Jun 22',
          money: "$3",
        },
      ];
    const config = {
        width:800,
        height:250,
        smooth:true,
        data,
        isStack:false,
        yAxis: {
            grid: {
              line: {
                style: {
                  stroke: 'black',
                  lineWidth: 0,
                  lineDash: [4, 5],
                  strokeOpacity: 0.7,
                  shadowColor: 'black',
                  shadowBlur: 10,
                  shadowOffsetX: 5,
                  shadowOffsetY: 5,
                  cursor: 'pointer'
                }
              }
            }
          },
        lineStyle: {
              fill: '#da9cea',
              fillOpacity: 0.2,
              stroke: '#9a00df',
              lineWidth: 4,
              strokeOpacity: 1,
              shadowColor: '#9a00df',
              shadowBlur: 4,
              shadowOffsetX: 0,
              shadowOffsetY: 0,
              cursor: 'pointer'
            },
        xField: 'year',
        yField: 'money',
        label: {},
        point: {
          size: 0,
          shape: 'diamond',
          style: {
            fill: 'white',
            stroke: '#5B8FF9',
            lineWidth: 2,
          },
        },
        tooltip: {
          showMarkers: false,
        },
        state: {
          active: {
            style: {
              shadowBlur: 0,
              stroke: 'red',
              fill: 'red',
            },
          },
        },
        interactions: [{ type: 'tooltip', enable: false }]
    };
const Platfrom = ()=>{
    const [betconver,setbetconver] = useState(false)
    const [show, setShow] = useState(false);
    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);
    const [current, setCurrent] = useState(0);
    const [strategymode, setstrategymode] = useState(false);
    const [Location, setLocation] = useState(true);
    const [Range, setRange] = useState(false);
    const [Streak, setStreak] = useState(false);
    const [Team, setTeam] = useState(false);
    const [Stats, setStats] = useState(false);

    const next = () => {
        setCurrent(current + 1);
    };
    const prev = () => {
        setCurrent(current - 1);
    };
    const [modfil,setmodfil] = useState([])
    console.log(modfil)
    return(
        <>
            <Modal dialogClassName="modal-90w" aria-labelledby="example-custom-modal-styling-title" show={show} onHide={handleClose}>
                <Modal.Body>
                    <div className="d-flex align-items-center modal-bet-filter p-4">
                    {current > 0 && (
                        <i onClick={() => prev()} class="fa fa-long-arrow-left" aria-hidden="true"></i>
                    )}
                        <h5 className="m-0">Create Metric With Wizard</h5>
                    </div>
                    <div className="container d-flex flex-column align-items-center justify-content-center">
                        <div className="col-11">
                            <Steps current={current} labelPlacement="vertical">
                                {steps.map((item) => (
                                <Step key={item.title} title={item.title} />
                                ))}
                            </Steps>
                        </div>

                       
                        <div className="col-12 pt-4 metric-filter-boxes-main">
                            <h6>{steps[current].data2}</h6>
                            <p>{steps[current].data}</p>
                        </div>
                        
                        <div className="steps-content-map px-4">
                            <div className="row">
                                {current == 2?
                                <div className="filter_result ">
                                    <h1>50%</h1>
                                    <h5>Please wait! We are creating your strategy...</h5>
                                    <div style={{width:"80%"}}>
                                    <Progress showInfo={false} strokeColor="#00c442" percent={50} />
                                    <div className='col-12 pt-4 pb-3 d-flex align-items-center py-5 justify-content-center w-100 price-btn-outinle-2-2'>
                                        <button style={{width:"fit-content"}}>See result<img className='btn-animation-arrow' src={Arrow2} alt="" /><img className='btn-animation-arrow-2' src={Arrow2} alt="" /></button>
                                    </div>
                                    </div>
                                </div>
                                :
                                steps[current].content.map((data)=>
                                <div onClick={()=>{
                                 modfil.push({"id":data.id})
                                 setmodfil([...modfil])
                                }} className="col-lg-2 mt-2 px-1">
                                    <div className={modfil.length>0?modfil.filter(dataa=>dataa.id==data.id).length>0?"steps-content-text-2":"steps-content-text":"steps-content-text"}>{data.data}</div>
                                </div>
                                )
                                }
                                
                            </div>
                        </div>
                        <div className='col-12 pt-4 pb-3 d-flex align-items-center justify-content-end w-100 price-btn-outinle-2-2'>
                                {current < steps.length - 1 && (
                                    <button style={{width:"fit-content"}} onClick={() => next()}>{current == 1?"Create Result":"Add More Filter"}<img className='btn-animation-arrow' src={Arrow2} alt="" /><img className='btn-animation-arrow-2' src={Arrow2} alt="" /></button>
                                )}
                        </div>
                        {/* {current > 0 && (
                        <Button
                            style={{
                            margin: '0 8px',
                            }}
                            onClick={() => prev()}
                        >
                            Previous
                        </Button>
                        )} */}
                    </div>
                    
                </Modal.Body>
            </Modal>
        <div className="container-fluid Platform-base">
            <div className="row">
                <div className="col-lg-4 px-3 py-4">
                    <div style={{position:"relative"}}>
                        <div className="build-top-head">
                            {strategymode==true?
                            <img src={Buildsm} alt="" />:""
                            }
                            <h5 className="ml-2"> Build</h5>
                        </div>
                        <div className="platform-build-sec px-3 py-4">
                            {strategymode==true?
                            <div className="platform-build-sec-stats">
                                <h5>Stats</h5>
                                <div>
                                <Tooltip placement="right" title="Guide">
                                     <img src={Info_sign} alt="" />
                                </Tooltip>
                                </div>
                            </div>
                            :""
                            }
                            {strategymode==true?
                            <p>Combine stats to create your winning formula</p>
                            :
                            <p>Take your betting into your own hands with our world-class, easy to use statistical platform.</p>
                            }
                            {strategymode==true?
                            <Select
                            defaultValue="Select a Category"
                            style={{
                                width: 270,

                            }}
                            onChange={handleChange}
                            >
                            <Option value="Select Sports">Select a Category</Option>
                            <Option value="lucy">Lucy</Option>
                            <Option value="Yiminghe">yiminghe</Option>
                            </Select>
                            :
                            <div className='col-12 d-flex align-items-center flex-column w-100 price-btn-outinle-2-2'>
                                <div className="col-12 p-0">
                                <button onClick={handleShow}>Create Metric With Wizard<img className='btn-animation-arrow' src={Arrow2} alt="" /><img className='btn-animation-arrow-2' src={Arrow2} alt="" /></button>
                                </div>
                                <div className="col-12 p-0">
                                <button onClick={()=>{setstrategymode(true)}}>Create New Strategy<img className='btn-animation-arrow' src={Arrow2} alt="" /><img className='btn-animation-arrow-2' src={Arrow2} alt="" /></button>
                                </div>
                            </div>
                            }
                        </div>
                    </div>



                    {strategymode==true?
                    <div style={{position:"relative"}} className="mt-2">
                        <div className="platform-build-sec-filter px-3">
                            <div className="platform-build-sec-stats">
                                <h5>Filter</h5>
                                <div>
                                <Tooltip placement="right" title="Guide">
                                     <img src={Info_sign} alt="" />
                                </Tooltip>
                                </div>
                            </div>
                            <div className="row filtraton-engine">
                                <div
                                onClick={()=>{
                                    setLocation(true)
                                    setRange(false)
                                    setStreak(false)
                                    setTeam(false)
                                    setStats(false)
                                }} 
                                className="col-4 px-2 location">
                                    <div className="px-3 w-100 d-flex align-items-center justify-content-center"  style={{color:Location==true?"#8D0AE2":"",borderBottom:Location==true?"2px solid #8D0AE2":""}}> 
                                    Location
                                    </div>
                                </div>
                                <div 
                                 onClick={()=>{
                                    setLocation(false)
                                    setRange(true)
                                    setStreak(false)
                                    setTeam(false)
                                    setStats(false)
                                }} 
                                className="col-4 px-2 game_range">
                                     <div className="px-3 w-100 d-flex align-items-center justify-content-center"  style={{color:Range==true?"#8D0AE2":"",borderBottom:Range==true?"2px solid #8D0AE2":""}}> 
                                     Game Range
                                    </div>
                                    </div>
                                <div 
                                onClick={()=>{
                                    setLocation(false)
                                    setRange(false)
                                    setStreak(true)
                                    setTeam(false)
                                    setStats(false)
                                }} 
                                className="col-4 px-2 streak">
                                    <div className="px-3 w-100 d-flex align-items-center justify-content-center"  style={{color:Streak==true?"#8D0AE2":"",borderBottom:Streak==true?"2px solid #8D0AE2":""}}> 
                                    Streak/Record
                                    </div>
                                    </div>
                                <div 
                                onClick={()=>{
                                    setLocation(false)
                                    setRange(false)
                                    setStreak(false)
                                    setTeam(true)
                                    setStats(false)
                                }} 
                                className="col-4 px-2 team">
                                    <div className="px-3 w-100 d-flex align-items-center justify-content-center"  style={{color:Team==true?"#8D0AE2":"",borderBottom:Team==true?"2px solid #8D0AE2":""}}> 
                                    Team
                                    </div>
                                    </div>
                                <div 
                                 onClick={()=>{
                                    setLocation(false)
                                    setRange(false)
                                    setStreak(false)
                                    setTeam(false)
                                    setStats(true)
                                }}
                                className="col-4 px-2 stats">
                                     <div className="px-3 w-100 d-flex align-items-center justify-content-center"  style={{color:Stats==true?"#8D0AE2":"",borderBottom:Stats==true?"2px solid #8D0AE2":""}}> 
                                     Stats
                                    </div>
                                    </div>
                            </div>
                            <div className="platform-build-sec-stats">
                                <h5>{Location == true?"Location":""||Range == true?"Game Range":""||Streak == true?"Streak/Record":""||Team == true?"Team":""||Stats == true?"Statistics    ":""}</h5>
                                <div>
                                <Tooltip placement="right" title="Guide">
                                     <img src={Info_sign} alt="" />
                                </Tooltip>
                                </div>
                            </div>

                            {Location == true?
                            <div className="row Location-fil-body py-1">
                                <div className="col-6 ">
                                    <div className="Location-fil-tab">
                                       <input type="radio" name="" id="" /> Home
                                    </div>
                                </div>
                                <div className="col-6 ">
                                    <div className="Location-fil-tab">
                                    <input type="radio" name="" id="" /> Away
                                    </div>
                                </div>
                            </div>
                            :""||
                            Range == true?
                            <div className="row Location-fil-body py-1">
                                <div className="col-12">
                                <Slider range defaultValue={[20, 50]} />
                                </div>
                                <div className="col-5">
                                    <label htmlFor="">Game X</label>
                                    <input type="text" placeholder="0"/>
                                </div>
                                <div className="col-2 d-flex align-items-end justify-content-center">
                                    to
                                </div>
                                <div className="col-5">
                                    <label htmlFor="">Game Y</label>
                                    <input type="text" placeholder="0"/>
                                </div>
                            </div>
                            :""
                            ||
                            Streak == true?
                            <div className="row Location-fil-body py-1">
                                <div className="col-12">
                                <Select
                                defaultValue="Select Consecutive Wins"
                                style={{
                                    width: "100%",
                                    boxShadow:"none"
                                }}
                                onChange={handleChange}
                                >
                                <Option value="Select Sports">Select Consecutive Wins</Option>
                                <Option value="lucy">Lucy</Option>
                                <Option value="Yiminghe">yiminghe</Option>
                                </Select>
                                </div>
                                <div className="col-4 d-flex align-items-center justify-content-start">
                                    <p className="m-0">Your Team</p>
                                </div>
                                <div className="col-4 d-flex flex-column align-items-start justify-content-center">
                                    <label style={{color:"#a2b1d1"}} htmlFor="">Min</label>
                                    <input type="text" placeholder="0"/>
                                </div>
                                <div className="col-4 d-flex flex-column align-items-start justify-content-center">
                                    <label style={{color:"#a2b1d1"}} htmlFor="">Max</label>
                                    <input type="text" placeholder="0"/>
                                </div>
                                <div className="col-4 d-flex align-items-center justify-content-start">
                                    <p className="m-0">opponent Team</p>
                                </div>
                                <div className="col-4 d-flex align-items-center justify-content-start" >
                                    <input type="text" placeholder="0"/>
                                </div>
                                <div className="col-4 d-flex align-items-center justify-content-start">
                                    <input type="text" placeholder="0"/>
                                </div>
                            </div>
                            :""
                            ||
                            Team == true?
                            <div className="row Location-fil-body py-1">
                                <div className="col-12">
                                <label htmlFor="">Your Team</label>
                                <Select
                                defaultValue="Select Option"
                                style={{
                                    width: "100%",
                                    boxShadow:"none"
                                }}
                                onChange={handleChange}
                                >
                                <Option value="Select Sports">Select Option</Option>
                                <Option value="lucy">Lucy</Option>
                                <Option value="Yiminghe">yiminghe</Option>
                                </Select>
                                </div>

                                <div className="col-12 mt-2">
                                <label htmlFor="">Opponent</label>
                                <Select
                                defaultValue="Select Option"
                                style={{
                                    width: "100%",
                                    boxShadow:"none"
                                }}
                                onChange={handleChange}
                                >
                                <Option value="Select Sports">Select Option</Option>
                                <Option value="lucy">Lucy</Option>
                                <Option value="Yiminghe">yiminghe</Option>
                                </Select>
                                </div>
                            </div>
                            :""||
                            Stats == true?
                            <div className="row Location-fil-body py-1">
                                <div className="col-12">
                                <Select
                                defaultValue="Select Consecutive Wins"
                                style={{
                                    width: "100%",
                                    boxShadow:"none"
                                }}
                                onChange={handleChange}
                                >
                                <Option value="Select Sports">Select Consecutive Wins</Option>
                                <Option value="lucy">Lucy</Option>
                                <Option value="Yiminghe">yiminghe</Option>
                                </Select>
                                </div>
                                <div className="col-4 d-flex align-items-center justify-content-start">
                                    <p className="m-0">Your Team</p>
                                </div>
                                <div className="col-4 d-flex flex-column align-items-start justify-content-center">
                                    <label style={{color:"#a2b1d1"}} htmlFor="">Min</label>
                                    <input type="text" placeholder="0"/>
                                </div>
                                <div className="col-4 d-flex flex-column align-items-start justify-content-center">
                                    <label style={{color:"#a2b1d1"}} htmlFor="">Max</label>
                                    <input type="text" placeholder="0"/>
                                </div>
                                <div className="col-4 d-flex align-items-center justify-content-start">
                                    <p className="m-0">opponent Team</p>
                                </div>
                                <div className="col-4 d-flex align-items-center justify-content-start" >
                                    <input type="text" placeholder="0"/>
                                </div>
                                <div className="col-4 d-flex align-items-center justify-content-start">
                                    <input type="text" placeholder="0"/>
                                </div>
                            </div>
                            :""
                            }
                            <div className="row filter-last-button py-1 px-3">
                               <button>ADD FILTER</button>
                            </div>
                        </div>
                    </div>
                    :""}
                    {strategymode==true?
                        <>
                        <h5 className="your_strategy_last_form">Your Strategy</h5>
                        <div className="row">
                        <div className="col-6">
                            <div className="platform-build-sec-filter last_simple_base px-3">
                                <p className="py-2 m-0">Selected Metric</p>
                                <spaning>Scoring <i class="fa fa-times" aria-hidden="true"></i></spaning>
                                <spaning>Interception<i class="fa fa-times" aria-hidden="true"></i></spaning>
                                <button>CLEAR FILTER</button>
                            </div>  
                        </div>
                        <div className="col-6">
                            <div className="platform-build-sec-filter last_simple_base px-3">
                                <p className="py-2 m-0">Selected Filter</p>
                                <spaning>Home <i class="fa fa-times" aria-hidden="true"></i></spaning>
                                <spaning>GAme 2-4<i class="fa fa-times" aria-hidden="true"></i></spaning>
                                <button>CLEAR FILTER</button>
                            </div>  
                        </div>
                        <div className='col-12 py-3 d-flex align-items-center justify-content-center price-btn price-ctb-cstom'>
                            <button style={{width:"100%"}}>UPDATE RESULT <img className='btn-animation-arrow' src={Arrow} alt="" /><img className='btn-animation-arrow-2' src={Arrow} alt="" /></button>
                        </div>
                        </div>
                        </>
                    :""}
                </div>
                {strategymode==false?
                <div className="col-lg-8 px-3 py-4 bet-sec">
                <div style={{position:"relative"}}>
                        <div className="build-top-head">
                        {strategymode==true?
                            <img src={Bet} alt="" />:""
                            }<h5 className="ml-2">Bet</h5>
                        </div>
                        <div className="build-top-head-right">
                        <div>
                        <Select
                        defaultValue="Load Strategy"
                        style={{
                            width: 270,
                        }}
                        onChange={handleChange}
                        >
                        <Option value="Select Sports">Load Strategy</Option>
                        <Option value="lucy">Lucy</Option>
                        <Option value="Yiminghe">yiminghe</Option>
                        </Select>
                        </div>
                        <div className="d-flex align-items-center pb-3">
                            <div className="print-pdf mx-2"><img src={Save} alt="" /></div>
                            <div className="share-link mx-2"><img src={Sharelarge} alt="" /></div>
                        </div>
                        </div>
                        <div className="platform-build-sec px-3 pt-4 pb-0">
                            <div className="bet-converter">
                                <div className={betconver==false?"left-bet-converter-absolute":"left-bet-converter-absolute-op"} >
                                    <h6 className="m-0">{betconver==false?"Spread":"MoneyLine"}</h6>
                                </div>
                                <div onClick={()=>{setbetconver(false)}} className="left-bet-converter">
                                    <h6>Spread</h6>
                                </div>
                                <div onClick={()=>{setbetconver(true)}} className="right-bet-converter">
                                    <h6>MoneyLine</h6>
                                </div>
                            </div>
                            <div className="trading-meter-sec py-3">
                                <div style={{borderBottom:"none"}} className="trademeter text-center">
                                    <p className="m-0">Heavy Favorites</p>
                                    <div className="d-flex meter-num align-items-center">
                                        <p className="m-0">Under-5 <img src={Green1} alt="" /></p>
                                    </div>
                                </div>
                                <div style={{borderBottom:"none"}} className="trademeter text-center">
                                    <p  className="m-0">Close Favorites</p>
                                    <div className="d-flex meter-num align-items-center">
                                        <p  className="m-0">-5 to 0 <img src={Green2} alt="" /></p>
                                    </div>
                                </div>
                                <div style={{borderBottom:"none"}} className="trademeter text-center">
                                    <p  className="m-0">Close Underdogs</p>
                                    <div className="d-flex meter-num align-items-center">
                                        <p style={{color:"red"}} className="m-0">0 to +5 <img src={red1} alt="" /></p>
                                    </div>
                                </div>
                                <div style={{borderBottom:"none"}} className="trademeter text-center">
                                    <p  className="m-0">Heavy Underdogs</p>
                                    <div className="d-flex meter-num align-items-center">
                                        <p style={{color:"red"}} className="m-0">Over +5 <img src={red2} alt="" /></p>
                                    </div>
                                </div>
                                <div style={{borderBottom:"none"}} className="trademeter text-center">
                                    <p>Custom</p>
                                </div>
                            </div>
                            <hr className="my-0"/> 
                            <div className="col-12 d-flex align-items-center justify-content-center start-metric-sec">
                              <h6>start strategy by Creating Metric</h6>
                            </div>
                        </div>
                    </div>

                </div>:
                <>
                 <div className="col-lg-8 px-3 py-4 bet-sec">
                <div style={{position:"relative"}}>
                        <div className="build-top-head">
                        {strategymode==true?
                            <img src={Bet} alt="" />:""
                            }<h5 className="ml-2">Bet</h5>
                        </div>
                        <div className="build-top-head-right">
                        <div>
                        <Select
                        defaultValue="Load Strategy"
                        style={{
                            width: 270,
                        }}
                        onChange={handleChange}
                        >
                        <Option value="Select Sports">Load Strategy</Option>
                        <Option value="lucy">Lucy</Option>
                        <Option value="Yiminghe">yiminghe</Option>
                        </Select>
                        </div>
                        <div className="d-flex align-items-center pb-3">
                            <div className="print-pdf mx-2"><img src={Save} alt="" /></div>
                            <div className="share-link mx-2"><img src={Sharelarge} alt="" /></div>
                        </div>
                        </div>
                        <div className="platform-build-sec px-3 pt-4 pb-0">
                            <div className="bet-converter">
                                <div className={betconver==false?"left-bet-converter-absolute":"left-bet-converter-absolute-op"} >
                                    <h6 className="m-0">{betconver==false?"Spread":"MoneyLine"}</h6>
                                </div>
                                <div onClick={()=>{setbetconver(false)}} className="left-bet-converter">
                                    <h6>Spread</h6>
                                </div>
                                <div onClick={()=>{setbetconver(true)}} className="right-bet-converter">
                                    <h6>MoneyLine</h6>
                                </div>
                            </div>
                            <div className="trading-meter-sec pb-3">
                                <div className="trademeter-1 text-center">
                                    <p className="m-0">Heavy Favorites</p>
                                    <div className="d-flex meter-num flex-column justify-content-center">
                                        <p  className="m-0">Under-5 <img src={Green1} alt="" /></p>
                                        <p style={{color:"#11418f"}} className="m-0">ROI 10%</p>
                                    </div>
                                </div>
                                <div className="trademeter text-center">
                                    <p  className="m-0">Close Favorites</p>
                                    <div className="d-flex meter-num flex-column justify-content-center">
                                        <p  className="m-0">-5 to 0 <img src={Green2} alt="" /></p>
                                        <p style={{color:"#11418f"}} className="m-0">ROI 10%</p>
                                    </div>
                                </div>
                                <div className="trademeter text-center">
                                    <p  className="m-0">Close Underdogs</p>
                                    <div className="d-flex meter-num flex-column justify-content-center">
                                        <p style={{color:"red"}} className="m-0">0 to +5 <img src={red1} alt="" /></p>
                                        <p style={{color:"#11418f"}} className="m-0">ROI 10%</p>

                                    </div>
                                </div>
                                <div className="trademeter text-center">
                                    <p className="m-0">Heavy Underdogs</p>
                                    <div className="d-flex meter-num flex-column justify-content-center">
                                        <p style={{color:"red"}} className="m-0">Over +5 <img src={red2} alt="" /></p>
                                        <p style={{color:"#11418f"}} className="m-0">ROI 10%</p>
                                    </div>
                                </div>
                                <div className="trademeter text-center">
                                    <p className="m-0">Custom</p>
                                    <div className="d-flex meter-num flex-column justify-content-center">
                                        <p style={{color:"red"}} className="m-0">Over +5 <img src={red2} alt="" /></p>
                                        <p style={{color:"#11418f"}} className="m-0">ROI 10%</p>
                                    </div>
                                </div>
                            </div>
                            <div className="col-12">
                                <div className="row">
                                    <div className="col-lg-6">
                                    <div className="platform-build-sec-stats">
                                        <h5>Historical Performance</h5>
                                        <div>
                                        <Tooltip placement="right" title="Guide">
                                            <img src={Info_sign} alt="" />
                                        </Tooltip>
                                        </div>
                                    </div>
                                    <div className="history_down d-flex align-items-center justify-content-around">
                                        <div className="d-flex align-items-center">
                                        <p>Win%</p>
                                        <p style={{fontWeight:600,color:"#00c442"}}>50%</p>
                                        </div>

                                        <div className="d-flex align-items-center">
                                        <p>Records</p>
                                        <p style={{fontWeight:600,color:"#00c442"}}>52-21-3</p>
                                        </div>

                                        <div className="d-flex align-items-center">
                                        <p>ROI</p>
                                        <p style={{fontWeight:600,color:"#9a00df "}}>10%</p>
                                        </div>
                                    </div>
                                    </div>
                                    <div className="col-lg-6 anti-map-chart-date">
                                        <div className="d-flex align-items-end justify-content-end starategy-card-time-zone py-2 h-100">
                                            <div className="px-2">
                                                <label>Start Date</label>
                                                <div className="Date-format mx-2">
                                                    Oct/2021 <img className="ml-4" src={TimeZone} alt="" />
                                                </div>
                                            </div>
                                            <div className="px-2">
                                            <label htmlFor="">End Date</label>
                                            <div className="Date-format mx-2">
                                                Oct/2021 <img className="ml-4" src={TimeZone} alt="" />
                                            </div>
                                            </div>
                                        </div> 
                                    </div>
                                </div>
                            </div>
                            <div className="col-12 d-flex align-items-center justify-content-center start-metric-sec my-4">
                            <Line {...config} />
                            </div>
                        </div>
                    </div>
                </div>
                </>
                }
            </div>
        </div>
        </>
    )
}

export default Platfrom;