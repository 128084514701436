import React from 'react'
import Footer from './Footer'
import Header from './Header'
import SideBar from './SideBar'
import phone from '../images/account_sub/phone_duotone_line.png'
import message from '../images/account_sub/Message.png'
import Box from '@mui/material/Box';
import Slider from '@mui/material/Slider';
import Switch from '@mui/material/Switch';

const AccountSub = () => {
    function valuetext(value) {
        return `${value}°C`;
    }

    const [checked1, setChecked1] = React.useState(false);
    

    const handleChange1 = (event) => {
        setChecked1(event.target.checked);
    };
    const handleChange2 = (event) => {
        setChecked2(event.target.checked);
    };
    const handleChange3 = (event) => {
        setChecked3(event.target.checked);
    };
    const [checked2, setChecked2] = React.useState(false);
    const [checked3, setChecked3] = React.useState(false);
    return (
        <>
            <Header />
            <div className="container-fluid lprp">
                <div className="row">
                    <div className="col-lg-3 col-md-5 col-sm-12">
                        <SideBar />
                    </div>
                    <div className="col-lg-4 col-md-7 col-sm-12">
                        <div className="profile_heading">
                            <h3>Manage Subscription</h3>
                            <span>Get subscription to create your own strategy</span>
                        </div>
                        <div className="sub_box-1">
                            <div className="box_1-img">
                                <img src={phone} alt="" />
                            </div>
                            <div className="sub_box-1_content">
                                <h1>Link phone number</h1>
                                <span>For information about special offers</span>
                            </div>
                        </div>
                        <div className="sub_box-2">
                            <div className="box_2-img">
                                <img style={{ paddingLeft: '8px' }} src={message} alt="" />
                            </div>
                            <div className="sub_box-2_content">
                                <h1>Link email address</h1>
                                <span>For information about special offers</span>
                            </div>
                        </div>
                    </div>
                    <div className="col-lg-4 col-md-6 col-sm-12 dashboard-subscirebed" style={{ marginTop: '165px' }} >
                        <div className="d">
                            <Switch
                                checked={checked1}
                                onChange={handleChange1}
                                inputProps={{ 'aria-label': 'controlled' }}
                            />
                            <span style={{ color: '#25408F ' }}>Recieve news about events  by email</span>

                        </div>
                        <div className="d">
                            <Switch
                                checked={checked2}
                                onChange={handleChange2}
                                inputProps={{ 'aria-label': 'controlled' }}
                            />
                            <span style={{ color: '#25408F ' }}>Recieve news about events by email</span>

                        </div>
                        <div className="d">
                            <Switch
                                checked={checked3}
                                onChange={handleChange3}
                                inputProps={{ 'aria-label': 'controlled' }}
                            />
                            <span style={{ color: '#25408F ' }}>Recieve news about events by email</span>

                        </div>

                    </div>
                </div>
            </div>
            <Footer />
        </>
    )
}

export default AccountSub