import React from 'react';
import "../App.css";
import { Collapse, Space } from 'antd';
const { Panel } = Collapse;


const text = `
  A dog is a type of domesticated animal.
  Known for its loyalty and faithfulness,
  it can be found as a welcome guest in many households across the world.
`;


const Questions = () => {
    return(
        <>
        <div id='question'>
            <div className='container-fluid text-center  question-heading pt-0' >
                <h1>Frequently <span>Asked</span> Questions</h1>
            </div>
            <div className='container-fluid pt-0' >
                <div className='container'>
                    <div className='col-12'>
                <Space direction="vertical" > 
                    <Collapse expandIconPosition="end" accordion={true}>
                    <Panel header="Q. How do I start?" key="1">
                        <p>{text}</p>
                    </Panel>
                   
                    <Panel header="Q. How do I build a metric?" key="2">
                        <p>{text}</p>
                    </Panel>

                    <Panel header="Q. What is the best to use the system?" key="3">
                        <p>{text}</p>
                    </Panel>
                    </Collapse>
                </Space>
                </div>
                </div>
            </div>
        </div>
        </>
        )
    };
    
export default Questions;