import React from 'react'
import message from '../images/account_sub/Message_duotone_line.png'
import line from '../images/account_sub/Line_duotone.png'
import question from '../images/account_sub/Question_duotone.png'
import bet from '../images/account_sub/bet.png'
import { Routes, Route, Link, NavLink } from "react-router-dom";
import sign from '../images/account_sub/Sign_in_circle_duotone_line.png'
import user from '../images/account_sub/user.png'
const SideBar = () => {
    return (
        <>
            <div className="side_menu">
                <div className="side_menu-heading">
                    <sub>Welcome</sub>
                    <h3>Sarah John</h3>
                </div>
                <div className="mask_bg"></div>
            </div>
            <div className="side_content">
                <ul>
                    <li> <img src={user} alt="" /><NavLink to="/createProfile">Personal Information</NavLink></li>
                    <li><img src={message} alt="" /> <NavLink to='/Subscribe'>subscription</NavLink></li>
                    <li><img src={line} alt="" /> <Link to='#'>Invite friends</Link></li>
                    <li><img src={bet} alt="" /><Link to='#'>Bet setting</Link></li>
                    <li> <img src={question} alt="" /><Link to='#'>Help & support</Link></li>
                    <li><img src={sign} alt="" /><Link to='#'> LogOut</Link></li>
                </ul>
            </div>
        </>
    )
}

export default SideBar