import React, { useEffect, useState } from "react";
import Header from "../component/Header";
import { Skeleton } from 'antd';
import Rectanlge from "../images/Rectangle 4031.png"
import Link1 from "../images/Group 91.png"
import Link2 from "../images/Group 92.png"
import SearchBar from "../images/Search_duotone_line.png"
import BlogImage from "../images/Rectangle 4027.png"
import Arrow2 from "../images/arrow2.png"

import Footer from "../component/Footer";
import { Tabs } from 'antd';
import { Link } from "react-router-dom";
const { TabPane } = Tabs;

const BlogPage = ()=>{
    const [globesearch,setglobesearch] =useState(false)
    const [Blogdata,setBlogdata] =useState([])
    const [Filter,setFilter] = useState("Forecasts")
    const [Loader,setloader] = useState(false)
    const [Wordfil,setwordfil] = useState("")
    const [pagenum,setpagenum] = useState()
    const [pagination,setpagination] = useState(1)
    useEffect(()=>{
        try{
            if(globesearch == true){
            setloader(true)
            fetch(`https://api.dropinblog.com/v1/json/?b=faca0e7d-1520-4086-94ec-a6359f10807c&limit=10000`)
                .then(res=>res.json())
                .then(response=>{
                    setBlogdata(response.data.posts)
                    setpagenum(response.data.next)
                    setloader(false)
                })
            }
            else if(Wordfil==""){
                setloader(true)
                fetch(`https://api.dropinblog.com/v1/json/?b=faca0e7d-1520-4086-94ec-a6359f10807c&limit=9&category=${Filter}&page=${pagination}`)
                .then(res=>res.json())
                .then(response=>{
                    setBlogdata(response.data.posts)
                    setpagenum(response.data.next)
                    setloader(false)
                })
            }
            else{
                setloader(true)
                fetch(`https://api.dropinblog.com/v1/json/?b=faca0e7d-1520-4086-94ec-a6359f10807c&limit=9&category=${Filter}&page=${pagination}`)
                .then(res=>res.json())
                .then(response=>{
                    setBlogdata(response.data.posts)
                    setpagenum(response.data.next)
                    setloader(false)
                })
            }
        }
        catch (e) {
            console.log('error')
            setloader(false)
        }
    },[globesearch,Filter,pagination])


    return(
        <>
            <Header/>
                <div id="main_blog_block" className="container-fluid">
                        <div className="col-12 pt-5">
                            <div className="d-flex align-items-center justify-content-center pricing_heading ">
                            <h2 className="m-0"><span>News & Resources</span></h2>
                            </div>
                        </div>
                        <div className="col-12 p-5 responsive-padding-0">
                            <div className="news-lettet-image-box">
                            <div className="row">
                                <div className="col-lg-6 col-sm-12">
                                    <img style={{width:"100%",height:"100%"}} src={Rectanlge} alt="" />
                                </div>
                                <div className="col-lg-6 pr-5 col-sm-12">
                                    <div className="news-lettet-image-box-right-tag pt-5 px-3">
                                        <span>ALLOY NEWS</span>
                                    </div>  
                                    <div className="news-lettet-image-box-right text-left pt-4 px-3">
                                        <h2>Alloy named finalist for University of Chicago’s New Venture Challenge</h2>
                                    </div>
                                    <div className="news-lettet-image-box-right-para px-3">
                                        <p>We begin this week with the Warriors and Celtics tied in the NBA Finals series at 2-2. For this week’s Alloy Forecast, we’re looking back at a strategy we built a few weeks back, the “Backs Against the Wall” strategy.</p>
                                    </div>
                                    <div className="news-lettet-image-box-right-para news-lettet-image-box-right-date-links px-3">
                                        <p>Thu 7/19, 1:51 PM</p>
                                        <div className="py-5">
                                            <a href="" className="px-3">
                                                <img src={Link1} alt="" />
                                            </a>
                                            <a href="" className="px-3">
                                            <img src={Link2} alt="" />
                                            </a>
                                        </div>
                                    </div> 
                                </div>
                            </div>
                            </div>
                        </div>
                        <div className="col-12 px-5 py-4 responsive-padding-0-1">
                            <div className="filteration-bar">
                                <div className="row h-100">
                                <div className="col-lg-8 col-md-12 col-sm-12 d-flex filtration-func-sec align-items-center h-100">
                                    <div className="px-1">
                                    <button style={{color:Filter=="forecasts"?"#9a00df":"black",borderBottom:Filter=="forecasts"?"2px solid #9a00df":""}} onClick={()=>{
                                        setFilter("forecasts")
                                        setpagination(0)    
                                        setglobesearch(false)
                                    }}>Forecasts</button>
                                    </div>
                                    <div className="px-1">
                                    <button style={{color:Filter=="betting-education"?"#9a00df":"black",borderBottom:Filter=="betting-education"?"2px solid #9a00df":""}} onClick={()=>{
                                        setFilter("betting-education")
                                        setpagination(0)      
                                        setglobesearch(false)
                                    }}>Betting Education</button>
                                    </div>
                                    <div className="px-1">
                                    <button style={{color:Filter=="alloy-tips"?"#9a00df":"black",borderBottom:Filter=="alloy-tips"?"2px solid #9a00df":""}} onClick={()=>{
                                        setFilter("alloy-tips")
                                        setpagination(0)  
                                        setglobesearch(false)
                                    }}>Alloy Tips</button>
                                    </div>
                                    <div className="px-1">
                                    <button style={{color:Filter=="alloy-news"?"#9a00df":"black",borderBottom:Filter=="alloy-news"?"2px solid #9a00df":""}} onClick={()=>{
                                        setFilter("alloy-news")
                                        setpagination(0)  
                                        setglobesearch(false)
                                    }}>Alloy News</button>
                                    </div>
                                    <div className="px-1">
                                    <button style={{color:Filter=="betting-news"?"#9a00df":"black",borderBottom:Filter=="betting-news"?"2px solid #9a00df":""}} onClick={()=>{
                                        setFilter("betting-news")
                                        setpagination(0)  
                                        setglobesearch(false)
                                    }}>Betting News</button>
                                    </div>
                                </div>
                                <div className="col-lg-4 col-md-12 col-sm-12 h-100 filtration-input-sec d-flex align-items-center justify-content-center">
                                    <input type="text" onChange={(e)=>{
                                        setwordfil(e.target.value)
                                        setglobesearch(true)
                                    }} placeholder="Search Resources"/>
                                    <img  src={SearchBar} alt="" />
                                </div>
                                </div>
                            </div>
                        </div>

                        <div className="col-12 px-5 responsive-padding-0">
                            {Loader==false?
                            <div className="row h-100">
                                {Blogdata!==undefined&&Blogdata!==null&&Blogdata.length>0&&Blogdata!==""?
                                    Blogdata.filter(post => {
                                    if (Wordfil === '') {
                                      return post;
                                    } else if (post.title.toLowerCase().includes(Wordfil.toLowerCase())) {
                                      return post;
                                    }
                                    }).map(data=>{
                                    return(
                                <div className="col-lg-4 col-md-6 col-sm-12  mt-3">
                                    <div className="main-blog-card">
                                        <div>
                                            <img src={data.featuredImage} alt="" />
                                        </div>
                                        <div className="p-3">
                                            <div className="news-lettet-image-box-right-tag-2 pt-2">
                                            <span>{data.categories[0].title}</span>
                                            </div>  
                                            <div className="news-lettet-image-box-right-2 text-left pt-4">
                                            <Link to={`/blogdetail?blog=${data.slug}`}>

                                                <h5 style={{color:"black"}}>
                                                    {data.title}
                                                    </h5>
                                                    </Link>
                                            
                                            </div>

                                            <div className="news-lettet-image-box-right-para news-lettet-image-box-right-date-links ">
                                                <p>{data.updatedAt}</p>
                                            </div> 
                                        </div>
                                    </div>
                                </div>
                                    )}):""}
                            </div>:
                            <>
                            <div className="row h-100">
                                <div className="col-lg-4 col-lg-12 mt-3">
                                    <Skeleton active/>
                                </div>
                                <div className="col-lg-4 col-lg-12 mt-3">
                                    <Skeleton active/>
                                </div>
                                <div className="col-lg-4 col-lg-12 mt-3">
                                    <Skeleton active/>
                                </div>
                            </div>
                            </>
                            }
                        </div>
                        {globesearch == true?"":
                        <div className="col-12 px-5 py-4 d-flex align-items-center justify-content-end">
                            <div className='d-flex align-items-center justify-content-between w-100 price-btn-outinle-2'>
                                {pagination == 1?
                                <button style={{visibility:"hidden"}} onClick={()=>{setpagination(pagination - 1)}}><img className='btn-animation-arrow' src={Arrow2} alt="" /><img className='btn-animation-arrow-2' src={Arrow2} alt="" />Back</button>
                                
                                :
                                <button onClick={()=>{setpagination(pagination - 1)}}><img className='btn-animation-arrow' src={Arrow2} alt="" /><img className='btn-animation-arrow-2' src={Arrow2} alt="" />Back</button>
                        }
                                <button onClick={()=>{setpagination(pagenum)}}>Next<img className='btn-animation-arrow' src={Arrow2} alt="" /><img className='btn-animation-arrow-2' src={Arrow2} alt="" /></button>
                            </div>
                        </div>
                        }
                </div>
            <Footer/>
        </>
    )
}

export default BlogPage