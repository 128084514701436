import React from 'react'
import Footer from './Footer';
import Header from './Header';
import Arrow from "../images/Vector9.png"
import Mask from '../images/account_sub/Mask group.png'
import message from '../images/account_sub/Message_duotone_line.png'
import line from '../images/account_sub/Line_duotone.png'
import question from '../images/account_sub/Question_duotone.png'
import bet from '../images/account_sub/bet.png'
import sign from '../images/account_sub/Sign_in_circle_duotone_line.png'
import user from '../images/account_sub/user.png'
import input_1 from '../images/account_sub/Edit_duotone.png'
import input_2 from '../images/account_sub/Lock_duotone_line.png'
import SideBar from './SideBar';
const AccountPro = () => {
    return (
        <>
            <Header />
            <div className="container-fluid lprp">
                <div className="row">
                    <div className="col-lg-3 col-md-5 col-sm-12">
                        <SideBar />
                    </div>
                    <div className="col-lg-4 col-md-7 col-sm-12">
                        <div className="profile_heading">
                            <h3>Personal Profile</h3>
                            <span>Full your personal information</span>
                        </div>
                        <div className="account_form">
                            <h1>Account</h1>
                            <div className="inputs my-1" style={{ position: 'relative', width: "fit-content" }}>
                                <input placeholder='Username' type="text" className="form-control" />
                                <img className='svg' style={{ position: 'absolute', right: '10px', bottom: '4px' }} src={input_1} alt="" />
                            </div>
                            <div className="inputs my-1" style={{ position: 'relative', width: "fit-content" }}>
                                <input placeholder='E-mail Address' type="text" className="form-control" />
                                <img className='svg' style={{ position: 'absolute', right: '10px', bottom: '4px' }} src={input_2} alt="" />
                            </div>
                            <div className="inputs my-1" style={{ position: 'relative', width: "fit-content" }}>
                                <input placeholder='*****' type="password" className="form-control" />
                                <img className='svg' style={{ position: 'absolute', right: '10px', bottom: '4px' }} src={input_1} alt="" />
                            </div>
                            <div className="inputs my-1" style={{ position: 'relative', width: "fit-content" }}>
                                <input placeholder='Phone Number' type="text" className="form-control" />
                                <img className='svg' style={{ position: 'absolute', right: '10px', bottom: '4px' }} src={input_1} alt="" />
                            </div>
                           
                            <div className='land-scape-auth'>
                                <h1>Personal information</h1>
                                <div className='right_input my-1' style={{ position: 'relative', width: "fit-content" }}>
                                    <input placeholder='Sarah' type="text" className="form-control" />
                                    <img className='svg' style={{ position: 'absolute', right: '10px', bottom: '4px' }} src={input_1} alt="" />
                                </div>
                                <div className='right_input my-1' style={{ position: 'relative', width: "fit-content" }}>
                                    <input placeholder='John' type="text" className="form-control" />
                                    <img className='svg' style={{ position: 'absolute', right: '10px', bottom: '4px' }} src={input_1} alt="" />
                                </div>
                                <div className='right_input my-1' style={{ position: 'relative', width: "fit-content" }}>
                                    <input placeholder='USA' type="text" className="form-control" />
                                    <img className='svg' style={{ position: 'absolute', right: '10px', bottom: '4px' }} src={input_1} alt="" />
                                </div>
                            </div>
                            <div className='d-flex align-items-center justify-content-start price-btn media_button  ' style={{ paddingTop: '23px', }}>
                                <button className='media_button' style={{ width: '361px', height: '50px' }}>Save <img className='btn-animation-arrow' src={Arrow} alt="" /><img className='btn-animation-arrow-2' src={Arrow} alt="" /></button>
                            </div>

                        </div>
                          
                    </div>
                    <div className="col-lg-4 col-md-6 col-sm-12 extra-left-account" >
                        <div className="right_form">
                            <h1>Personal information</h1>
                            <div className='right_input my-1' style={{ position: 'relative', width: "fit-content" }}>
                                <input placeholder='Sarah' type="text" className="form-control" />
                                <img className='svg' style={{ position: 'absolute', right: '10px', bottom: '4px' }} src={input_1} alt="" />
                            </div>
                            <div className='right_input my-1' style={{ position: 'relative', width: "fit-content" }}>
                                <input placeholder='John' type="text" className="form-control" />
                                <img className='svg' style={{ position: 'absolute', right: '10px', bottom: '4px' }} src={input_1} alt="" />
                            </div>
                            <div className='right_input my-1' style={{ position: 'relative', width: "fit-content" }}>
                                <input placeholder='USA' type="text" className="form-control" />
                                <img className='svg' style={{ position: 'absolute', right: '10px', bottom: '4px' }} src={input_1} alt="" />
                            </div>
                        </div>
                    </div>
                    
                </div>
            </div>
            <Footer />
        </>
    )
}
export default AccountPro;