import { Carousel } from 'antd';
import React from 'react';
import Arrow from "../images/Vector9.png"
import SliderImage from "../images/mob-slide-01.png"
import sliderImage2_responsive from "../images/mob-slide-02.png"
import SliderImage3_responsive from "../images/mob-slide-03.png"
import SliderImagepart1 from "../images/slide/slide1/011.png"
import SliderImagepart2 from "../images/slide/slide1/coin (1).png"
import SliderImagepart3 from "../images/slide/slide1/coin.png"
import SliderImagepart4 from "../images/slide/slide1/Group 110.png"
import SliderImage2 from "../images/slide/31.png"
import SliderImage2part1 from "../images/slide/filter.png"
import SliderImage2part2 from "../images/slide/Group 100.png"
import SliderImage2part3 from "../images/slide/Group 103.png"
import SliderImage3part1 from "../images/slide/slide3/slide-01-01.png"
import SliderImage3part2 from "../images/slide/slide3/Group 107.png"


import { useState } from 'react';
const contentStyle = {
  color: '#fff',
  height:"600px",
  display:"flex",
  alignItems:"center",
  justifyContent:"center",
};
const contentStyle2 = {
    color: '#fff',
    display:"flex",
    alignItems:"center",
    justifyContent:"center",
  };


const Slider = () => {
    const [firstimg,setfirstimg]= useState(false)
    const [secondimg,setsecondimg]= useState(false)
    const [thirdimg,setthirdimg]= useState(false)

    const [img1,setimg1] = useState(true)
    const [img2,setimg2] = useState(false)
    const [img3,setimg3] = useState(false)

    return(
        <>
        <div className='container-fluid'>
        <div className="row">
          <div className="col-md-12">
            <div className="pricing_heading">
              <h2> Create Your <span>Own Betting Success</span>  in <br/> 3 Easy Steps</h2>
            </div>
          </div>
        </div>
        </div>
        
        <div className='responsive-slider'>
        <Carousel effect="fade" arrows={true} infinite={true}>
            <div className='main-div-carousel col-12'>
            <div style={contentStyle2}  className='row'>
                <div className='right-slide-2 col-lg-6 col-md-12 col-sm-12 p-5' >
                    <img  src={SliderImage} alt="" />
                </div>
                <div style={{display:"flex",alignItems:"center",flexDirection:"column"}} className='left-slide col-lg-6 col-md-12 col-sm-12 p-0'>
                    <div className='silde-serial-num'>
                        <h2>1</h2>
                    </div>
                    <div className='pt-4 slider-name-sec'>
                        <h1>Create Metric</h1>
                    </div>
                    <div style={{textAlign:"center"}} className='slider-descrip-sec'>
                        <p>
                            Lorem ipsum dolor sit amit, consectetur
                            adipiscing elit. Nunc vulputate libro et velit
                            interdum, ac aliquet odio mattis.
                        </p>
                    </div>

                    <div className='d-flex align-items-center mt-4 pt-4 price-btn-3 pl-0'>
                                      <button>CREATE NEW METRIC <img className='btn-animation-arrow' src={Arrow} alt="" /><img className='btn-animation-arrow-2' src={Arrow} alt="" /> <span className='animation-span-1'></span> <span className='animation-span-2'></span></button>
                                    </div> 
                </div>
            </div>
            </div>
            <div className='main-div-carousel col-12'>
            <div style={contentStyle}  className='row'>
                <div className='right-slide-2 col-lg-6 col-sm-12 p-5' >
                    <img src={sliderImage2_responsive} alt="" />
                </div>
                <div style={{display:"flex",alignItems:"center",flexDirection:"column"}} className='left-slide col-lg-6 col-sm-12 p-0'>
                    <div className='silde-serial-num'>
                        <h2>2</h2>
                    </div>
                    <div className='pt-4 slider-name-sec'>
                        <h1>Create Metric</h1>
                    </div>
                    <div style={{textAlign:"center"}} className='slider-descrip-sec'>
                        <p>
                            Lorem ipsum dolor sit amit, consectetur
                            adipiscing elit. Nunc vulputate libro et velit
                            interdum, ac aliquet odio mattis.
                        </p>
                    </div>

                    <div className='d-flex align-items-center mt-4 pt-4 price-btn-3 pl-0'>
                                      <button>CREATE NEW METRIC <img className='btn-animation-arrow' src={Arrow} alt="" /><img className='btn-animation-arrow-2' src={Arrow} alt="" /> <span className='animation-span-1'></span> <span className='animation-span-2'></span></button>
                                    </div> 
                </div>
                </div>
            </div>
            <div className='main-div-carousel col-12'>
            <div style={contentStyle}  className='row'>
            <div className='right-slide-2 col-lg-6 col-sm-12 p-5' >
                    <img  src={SliderImage3_responsive} alt="" />
                </div>
                <div style={{display:"flex",alignItems:"center",flexDirection:"column"}} className='left-slide col-lg-6 col-sm-12 p-0'>
                    <div className='silde-serial-num'>
                        <h2>3</h2>
                    </div>
                    <div className='pt-4 slider-name-sec'>
                        <h1>Create Metric</h1>
                    </div>
                    <div style={{textAlign:"center"}} className='slider-descrip-sec'>
                        <p>
                            Lorem ipsum dolor sit amit, consectetur
                            adipiscing elit. Nunc vulputate libro et velit
                            interdum, ac aliquet odio mattis.
                        </p>
                    </div>

                    <div className='d-flex align-items-center mt-4 pt-4 price-btn-3 pl-0'>
                                      <button>CREATE NEW METRIC <img className='btn-animation-arrow' src={Arrow} alt="" /><img className='btn-animation-arrow-2' src={Arrow} alt="" /> <span className='animation-span-1'></span> <span className='animation-span-2'></span></button>
                                    </div> 
                </div>
               
                
                </div>
            </div>

        </Carousel>
        </div>


        
        <div className='desk-slider'>
        <Carousel effect="fade" arrows={true} infinite={true} beforeChange={(oldIndex, newIndex)=>{
                console.log(oldIndex, newIndex);
                if(newIndex === 0){
                    setfirstimg(true)
                    setimg1(true)
                    setimg2(false)
                    setimg3(false)
                }
                else{
                    setfirstimg(false)
                }



                if(newIndex === 1){
                    setsecondimg(true)
                    setimg2(true)
                    setimg1(false)
                    setimg3(false)
                }
                else{
                    setsecondimg(false)
                }



                if(newIndex === 2){
                    setthirdimg(true)
                    setimg3(true)
                    setimg1(false)
                    setimg2(false)
                }
                else{
                    setthirdimg(false)
                }
            }}>
                <div className='main-div-carousel col-12'>
                <div style={contentStyle}  className='row'>
                    <div className='left-slide col-lg-6 col-sm-12 pr-5'>
                        <div className='silde-serial-num'>
                            <h2>1</h2>
                        </div>
                        <div className='pt-4 slider-name-sec'>
                            <h1>Create Metric</h1>
                        </div>
                        <div className='slider-descrip-sec'>
                            <p>
                                Lorem ipsum dolor sit amit, consectetur
                                adipiscing elit. Nunc vulputate libro et velit
                                interdum, ac aliquet odio mattis.
                            </p>
                        </div>

                                        <div className='d-flex align-items-center price-btn price-ctb-cstom'>
                    <button>CREATE NEW METRIC <img className='btn-animation-arrow' src={Arrow} alt="" /><img className='btn-animation-arrow-2' src={Arrow} alt="" /></button>
                  </div>
                    </div>
                    <div className='right-slide col-lg-6 col-sm-12' >
                        <div className={firstimg === false ?"asdasdad":"right-slide-21-d"}>
                            <img className={img1 === true?"animate-next-img1":"animate-next-img-back"} src={SliderImagepart1} alt="" />
                            <img className={img1 === true?"animate-next-img1-anima-1":"animate-next-img-back"} src={SliderImagepart2} alt="" />
                            <img className={img1 === true?"animate-next-img1-anima-2":"animate-next-img-back"} src={SliderImagepart3} alt="" />
                            <img className={img1 === true?"animate-next-img1-anima-1":"animate-next-img-back"} src={SliderImagepart4} alt="" />
                        </div>
                    </div>
                    
                    </div>
                </div>
                <div className='main-div-carousel col-12'>
                <div style={contentStyle}  className='row'>
                    <div className='left-slide col-lg-6 pr-5 col-sm-12'>
                        <div className='silde-serial-num'>
                            <h2>2</h2>
                        </div>
                        <div className='pt-4 slider-name-sec'>
                            <h1>Build & Set</h1>
                        </div>
                        <div className='slider-descrip-sec'>
                            <p>
                                Lorem ipsum dolor sit amit, consectetur
                                adipiscing elit. Nunc vulputate libro et velit
                                interdum, ac aliquet odio mattis.
                            </p>
                        </div>

                        <div className='d-flex align-items-center price-btn price-ctb-cstom'>
                    <button>CREATE NEW METRIC <img className='btn-animation-arrow' src={Arrow} alt="" /><img className='btn-animation-arrow-2' src={Arrow} alt="" /></button>
                  </div>
                    </div>
                    <div className='right-slide col-lg-6 col-sm-12'>
                    <div className={secondimg === false ?"right-slide-22-d-ee":"right-slide-22-d"}>
                        <img className={img2 === true?"animate-next-img1":"animate-next-img-back"} src={SliderImage2} alt="" />
                        <img className={img2 === true?"animate-next-img1-anima-1":"animate-next-img-back"} src={SliderImage2part1} alt="" />
                        <img className={img2 === true?"animate-next-img1-anima-2":"animate-next-img-back"} src={SliderImage2part2} alt="" />
                        <img className={img2 === true?"animate-next-img1-anima-1":"animate-next-img-back"} src={SliderImage2part3} alt="" />
                    </div>
                    </div>
                    </div>
                </div>
                <div className='main-div-carousel col-12'>
                <div style={contentStyle}  className='row'>
                    <div className='left-slide col-lg-6 pr-5 col-sm-12'>
                        <div className='silde-serial-num'>
                            <h2>3</h2>
                        </div>
                        <div className='pt-4 slider-name-sec'>
                            <h1>Review Result</h1>
                        </div>
                        <div className='slider-descrip-sec'>
                            <p>
                                Lorem ipsum dolor sit amit, consectetur
                                adipiscing elit. Nunc vulputate libro et velit
                                interdum, ac aliquet odio mattis.
                            </p>
                        </div>
                        <div className='d-flex align-items-center price-btn price-ctb-cstom'>
                    <button>CREATE NEW METRIC <img className='btn-animation-arrow' src={Arrow} alt="" /><img className='btn-animation-arrow-2' src={Arrow} alt="" /></button>
                  </div>
                    </div>
                    <div className='right-slide col-lg-6 col-sm-12'>
                    <div className={thirdimg === false ?"right-slide-23":"right-slide-23-d"}>
                        <img className={img3 === true?"animate-next-img1":"animate-next-img-back"} src={SliderImage3part1} alt="" />
                        <img className={img3 === true?"animate-next-img1-anima-1":"animate-next-img-back"} src={SliderImage3part2} alt="" />
                    </div>
                    </div>
                    
                    </div>
                </div>

        </Carousel>
        </div>
               
        </>
    ) 
  };

export default Slider;