import React, { useEffect, useState } from "react";
import Header from "./Header";
import { Skeleton } from 'antd';
import Rectanlge from "../images/Rectangle 4031.png"
import Link1 from "../images/Group 91.png"
import Link2 from "../images/Group 92.png"
import SearchBar from "../images/Search_duotone_line.png"
import BlogImage from "../images/Rectangle 4027.png"
import Arrow2 from "../images/arrow2.png"
import { Link, useSearchParams } from "react-router-dom";
import Footer from "./Footer";
import { Tabs } from 'antd';
import ReactHtmlParser, { processNodes, convertNodeToElement, htmlparser2 } from 'react-html-parser';
const { TabPane } = Tabs;

const BlogDetail = ()=>{
    const [searchParams, setSearchParams] = useSearchParams();
    const blogid = searchParams.get("blog")
    const [Blogdata,setBlogdata] =useState([])
    console.log(Blogdata);
    const [Filter,setFilter] = useState("Forecasts")
    useEffect(()=>{
        try{
            fetch(`https://api.dropinblog.com/v1/json/post/?b=faca0e7d-1520-4086-94ec-a6359f10807c&post=${blogid}`)
            .then(res=>res.json())
            .then(response=>{
                const apidata = response.data.post
                const temp = []
                temp.push(apidata)
                setBlogdata(temp)
            })
        }
        catch (e) {
            console.log('error')
        }
    },[Filter])


    return(
        <>
            <Header/>
                <div id="main_blog_block-2" className="container-fluid">
                        {Blogdata!==undefined&&Blogdata!==null&&Blogdata!==""?Blogdata.map(data=>(
                            <>
                        <div className="col-lg-12 pt-0 col-sm-12 pt-4 d-flex align-items-center justify-content-center">
                            <div className="col-lg-10 col-sm-8 blog-detalil-image">
                                <div className="py-4">
                            </div>
                                <img  style={{borderRadius:"20px"}} className="mt-0 client-inner-page-banner"  src={data.featuredImage} alt="" />
                                <div className="d-flex align-items-center justify-content-center py-5 navigator-detail">
                                    <p>Home</p><span>/</span><p>News & Resources</p><span>/</span><p className="active-in-navigator">{data.title}</p>
                                </div>
                                
                                <div>
                                <div className="news-lettet-image-box-right-tag-2 pt-2">
                                            <span style={{color:"#11418f",border:"1px solid #11418f"}}>{data.categories[0].title}</span>
                                    </div>  
                                </div>
                                <div className="inner-detail-title pt-2">
                                <h3>{data.title}</h3>
                                </div>
                                <div className="subAuther d-flex align-items-center d-flex justify-content-between align-items-center">
                                        <p style={{color:"#8ca0c6 "}}>{data.publishedAt}</p>

                                        <div className="py-4">
                                            <a href="" className="px-3">
                                                <img src={Link1} alt="" />
                                            </a>
                                            <a href="" className="px-3">
                                            <img src={Link2} alt="" />
                                            </a>
                                        </div>
                                </div>
                                <div className="pt-5 html-parser-data">
                                        {ReactHtmlParser(data.content)}
                                    </div>
                            </div>
                        </div>
                        </>
                        )):""}
                        <div className="col-12 d-flex align-items-center justify-content-center">
                        <div className="col-10 py-4 d-flex align-items-center justify-content-end">
                            <div className='d-flex align-items-center justify-content-between w-100 price-btn-outinle-2'>
                                <Link to="/blog"><button>Previous Article</button></Link>
                            </div>
                        </div>
                        </div>
                </div>
            <Footer/>
        </>
    )
}

export default BlogDetail