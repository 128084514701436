import React from 'react';
import "../App.css";
import Arrow from "../images/Vector9.png"
import bullet from '../images/pricing-box-bullet.svg'


const Dropslide = () => {
  return (
    <section id="pricing_drop">
      <div className="container">
        <div className="row">
          <div className="col-md-12">
            <div className="pricing_heading">
              <h2> The <span>Right Price</span>  for You </h2>
              <h6>Free 30 day trial (no credit card needed).</h6>
            </div>
          </div>
        </div>
        <div className="col-md-12 col-12 mt-4">
          <div className="row">
            <div className='col-lg-6 col-12'>
              <div className='price-card p-0'>
                <div className='price-add'>
                  <div className='chip-limit'>MONTHLY</div>
                  <div className='price-chip pt-3'>
                    <h1>$14.99</h1><h6 className='ml-3'><del>$19.99</del></h6>
                  </div>
                  <h5 className='mt-2'>Lorem ipsum dolor sit amet, consectetur adipiscing elit.</h5>
                </div>
                <div className='product-price-detail'>
                  <div className="ppd-det">
                    <div className='d-flex align-items-center ppd-det-pointers'>
                      <div>
                        <img src={bullet} alt="" />
                      </div>
                      <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit.</p>
                    </div>
                    <div className='d-flex align-items-center ppd-det-pointers'>
                      <div>
                        <img src={bullet} alt="" />
                      </div>
                      <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit.</p>
                    </div>
                    <div className='d-flex align-items-center ppd-det-pointers'>
                      <div>
                        <img src={bullet} alt="" />
                      </div>
                      <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit.</p>
                    </div>
                    <div className='d-flex align-items-center ppd-det-pointers'>
                      <div>
                        <img src={bullet} alt="" />
                      </div>
                      <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit.</p>
                    </div>
                  </div>
                  <div className='d-flex align-items-center justify-content-center price-btn price-ctb-cstom'>
                    <button>START YOUR FREE TRIAL <img className='btn-animation-arrow' src={Arrow} alt="" /><img className='btn-animation-arrow-2' src={Arrow} alt="" /></button>
                  </div>
                </div>
              </div>
            </div>
            <div className='col-lg-6 col-12 cutom-margin-top'>
              <div className='price-card p-0'>
                <div className='price-add'>
                  <div className='chip-limit'>ANNUALY SAVE $70</div>
                  <div className='price-chip pt-3'>
                    <h1>$119.99</h1><h6 className='ml-3'><del>$199.99</del></h6>
                  </div>
                  <h5 className='mt-2'>Lorem ipsum dolor sit amet, consectetur adipiscing elit.</h5>
                </div>
                <div className='product-price-detail'>
                  <div className="ppd-det">
                    <div className='d-flex align-items-center ppd-det-pointers'>
                      <div>
                        <img src={bullet} alt="" />
                      </div>
                      <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit.</p>
                    </div>
                    <div className='d-flex align-items-center ppd-det-pointers'>
                      <div>
                        <img src={bullet} alt="" />
                      </div>
                      <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit.</p>
                    </div>
                    <div className='d-flex align-items-center ppd-det-pointers'>
                      <div>
                        <img src={bullet} alt="" />
                      </div>
                      <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit.</p>
                    </div>
                    <div className='d-flex align-items-center ppd-det-pointers'>
                      <div>
                        <img src={bullet} alt="" />
                      </div>
                      <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit.</p>
                    </div>
                  </div>
                  <div className='d-flex align-items-center justify-content-center price-btn price-ctb-cstom'>
                    <button>START YOUR FREE TRIAL <img className='btn-animation-arrow' src={Arrow} alt="" /><img className='btn-animation-arrow-2' src={Arrow} alt="" /></button>
                  </div>
                </div>

              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  )
};

export default Dropslide;